import React, { useEffect, useRef } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { shipmentModes } from "../../constants";

const QuotationDocument = ({ closeModal, quotation }) => {

  const componentRef = useRef();
  const contentRef = useRef();


  const printContent = (e) => {
    contentRef.current &&
      contentRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    // section.scrollIntoView({ behavior: "smooth", top: offsetTop });
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  return (
    <div className="h-[90vh] flex py-1 bg-white" ref={contentRef}>
      <div
        style={{ width: "750px" }}
        className="bg-neutral-100 noBorder px-6 py-3  overflow-y-auto overflow-x-hidden space-b-5 rounded h-full w-full border border-neutral-700"
        ref={componentRef}
      >
        {/* Header */}
        {/* <div
          style={{
            background: "#24b8b6c2",
          }}
          className="relative w-full text-white flex justify-between items-center rounded-t-lg"
        >
          <img src="vml_bg.svg" className="w-full" />
          <div className="absolute left-0 pl-10">
            <h1 className="text-xxs font-medium">Quotation Number</h1>
            <p className="text-2xl font-medium whitespace-pre-line mt-1">
              {quotation?.quotationNo}
            </p>
            <p className="text-base font-medium whitespace-pre-line mt-1">
              {quotation?.quotationDate}
            </p>
          </div>
          <div className="text-right absolute right-0 pr-10">
            <img src="vml_white_logo.svg" className="h-20" />
            <p className="text-xxxs font-medium text-left pr-5">
              Suite 630 2180 Evelyne <br /> Squares, Port Angeloport, OK
              <br />
              16889-2267{" "}
            </p>
          </div>
        </div> */}

        <div className="flex flex-row-reverse text-white bg-gray-400  rounded-t-md px-8 py-2 items-center justify-between">
          <img
            src="vml_white_logo.svg"
            className="h-16 "
            alt="Logo"
          />
          <p className="text-xs font-medium ">
            <span className="text-sm">Voyage Marine Logistics</span><br />
            Suite 630, 2180 Evelyne <br />
            Squares, Port Angeloport, OK <br />
            16889-2267
          </p>
        </div>


        <div className="flex flex-col items-center text-center mb-3">
          <h2 className="text-xl bg-gray-400 py-2 px-4 font-bold text-white w-full max-w-4xl">
            Quotation No: {quotation?.quotationNo || "N/A"}
          </h2>

          <div className="bg-white px-8 pt-4 pb-2 rounded-b-md border border-gray-200 w-full ">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-3 text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1">Customer</p>
                  <p className="text-xs text-gray-800 font-medium w-24">{quotation?.customerName || "N/A"}</p>
                  <p className="text-xxs w-32 font-medium whitespace-pre-line mt-1 leading-3 text-gray-600">
                    {quotation?.customerAddress || "N/A"}
                  </p>
                </div>
              </div>

              <div className=" col-span-3 text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1">Origin Port</p>
                  <p className="text-sm text-gray-800">{quotation?.originPortName || "Not Specified"}</p>
                </div>
              </div>

              <div className=" col-span-3 text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1">Destination Port</p>
                  <p className="text-sm text-gray-800">{quotation?.destinationPortName || "Not Specified"}</p>
                </div>
              </div>
              <div className=" col-span-3 text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1">Currency</p>
                  <p className="text-sm text-gray-800">{quotation?.currencyCode || "Not Specified"}</p>
                </div>
              </div>

              <div className=" col-span-3 text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1">Quotation Date</p>
                  <p className="text-sm text-gray-800">
                    {quotation?.quotationDate
                      ? new Date(quotation.quotationDate).toLocaleDateString("en-GB").split("/").join("-")
                      : "N/A"}
                  </p>
                </div>
              </div>

              <div className=" col-span-3 text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1">Validity Date</p>
                  <p className="text-sm text-gray-800">
                    {quotation?.validityDate
                      ? new Date(quotation?.validityDate).toLocaleDateString("en-GB").split("/").join("-")
                      : "_/ _/_ _ _"}
                  </p>
                </div>
              </div>

              <div className=" col-span-3 text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1">Email</p>
                  <p className="text-xs text-gray-800">{quotation?.customerEmail}</p>
                </div>
              </div>

              <div className="col-span-3 text-left">
                <div className="">
                  <p className="text-xs text-gray-500 mb-1">Contact Number</p>
                  <p className="text-xs text-gray-800">{quotation?.customerPhone}</p>
                </div>
              </div>

              {quotation?.comments && (
                <div className="flex gap-2 text-left">
                  <p className="mt-2 text-xs font-medium text-gray-800">Comment: </p>
                  <p className="mt-2 text-xs font-medium text-gray-800">{quotation?.comments}</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex w-full gap-3  mt-2 ">
          
          <div className="bg-white px-8 py-3 rounded w-full">
            <div className="grid grid-cols-5">
              <div>
                <p className="text-xs text-neutral-400 font-medium">
                  Pallets
                </p>
                <p className="text-xxs font-medium"> {quotation?.cargoDetails?.quantity} Pcs</p>
              </div>
              <div>
                <p className="text-xs text-neutral-400 font-medium">
                  Charge Weight
                </p>
                <p className="text-xxs font-medium"> {quotation?.cargoDetails?.chargeWeight} Kg</p>
              </div>
              <div>
                <p className="text-xs text-neutral-400 font-medium">
                  Gross Volume
                </p>
                <p className="text-xxs font-medium">
                  {quotation?.cargoDetails?.grossVolume} m3
                </p>{" "}
              </div>
              <div>
                <p className="text-xs text-neutral-400 font-medium">
                  Gross Weight
                </p>
                <p className="text-xxs font-medium">
                  {quotation?.cargoDetails?.grossWeight} Kg
                </p>
              </div>
              <div>
                <p className="text-xs text-neutral-400 font-medium">
                  Vessels
                </p>

                {(quotation?.cargoDetails?.vessels) && quotation.cargoDetails.vessels.length > 0 ? (
                  quotation.cargoDetails.vessels.map((vessel) => (
                    <p key={vessel.id} className="text-xxs font-medium">
                      {vessel.name}
                    </p>
                  ))
                ) : (
                  <p className="text-xxs font-medium text-neutral-400">No vessels available</p>
                )}

              </div>
            </div>

          </div>
        </div>

        <div className=" mt-2 pb-5 break-inside-avoid">
          {quotation.shipment.map((x) =>
          
            x.options.map((y, index) => (
              
              <div
                key={1}
                className="bg-white rounded-lg shadow-md flex items-center px-2 py-2 pr-3 gap-5 mt-2 "
              >
                <div

                  className="flex gap-4 bg-gray-400 justify-center items-center w-[160px] rounded-lg text-white p-4 "
                >
                  <div className="rounded-full bg-gray-600 p-1">
                    <img
                      src={quotation.modes.find((y) => y.id === x.mode)?.icon}
                      className="h-5 w-5 max-w-none"
                    />
                  </div>
                  {/* <span className="bg-yellow-300 absolute -left-2 top-4 text-xxs w-20 font-semibold px-2 py-1">
                      Option {index + 1}
                    </span>
                    <div className="absolute -left-2 top-10 w-0 h-0 border-l-8 border-t-8 border-r-0 border-solid border-l-transparent border-t-yellow-600"></div>
                    <span className="bg-yellow-300 absolute -left-2 top-4 text-xxs font-semibold px-2 py-1"></span>
                    <div className="absolute left-[65px] border-transparent top-5 w-0 h-0 border-l-[16px] border-t-[16px] rotate-45 border-r-0 border-solid border-l-transparent border-l-blue-400"></div>
                   */}
                  <h3 className="text-sm font-semibold text-center leading-tight">
                    {quotation.modes.find((y) => y.id === x.mode)?.mode} Transport {quotation.modes.find((y) => y.id === x.mode)?.mode.length>2 ? index+1:""}
                  
                  </h3>

                </div>

                <div className=" flex justify-between items-center gap-6 py-3">
                  <div>
                    <p className="text-gray-500 text-xs">Origin</p>
                    <p className="text-sm font-medium">{x?.options[0]?.originName}</p>
                  </div>

                  <div>
                    <p className="text-gray-500 text-xs">Destination</p>
                    <p className="text-sm font-medium">{x?.options[0]?.destinationName}</p>
                  </div>

                  {/* <div>
                    <p className="text-gray-500 text-xs">Weight</p>
                    <p className="text-sm font-medium">0</p>
                  </div> */}

                  <div>
                    <p className="text-gray-500 text-xs">{y.services.length} Locations</p>
                    <p className="text-gray-500 text-xs">
                      {y.services.reduce((servSum, service) => {
                        return servSum + service.details.length;
                      }, 0)}{" "}
                      Services
                    </p>
                  </div>

                  <p className="text-gray-800 font-semibold">
                    Total: $
                    {y.services.reduce((servSum, service) => {
                      return (
                        servSum +
                        service.details.reduce((detSum, detail) => {
                          return detSum + detail.price * detail.quantity;
                        }, 0)
                      );
                    }, 0)}
                  </p>
                </div>
              </div>

            ))
          )}
        </div>

        <div className="bg-white p-5 mb-5 rounded-lg shadow-md print:break-before-page  ">


          {quotation?.shipment?.map((ship, index) => (
            <div key={index} className="border-t border-gray-200 pt-3 mb-3 print:break-before-page">
              <div className="text-xl font-semibold mb-4 text-white bg-gray-400 flex items-center px-2 py-1 gap-2">
                <div className="rounded-full bg-gray-600 p-1 flex justify-center items-center">
                  <img src={shipmentModes.find((x) => x.id === ship.mode)?.icon} className="h-5 w-5" />
                </div>
                {shipmentModes.find((x) => x.id === ship.mode)?.mode}
              </div>
              <h3 className="font-bold text-lg text-gray-800 mb-1">Service Details</h3>
              {ship.options?.map((option, optionIndex) => (
                <div key={optionIndex} className="mb-4">

                  <p className="my-1">{shipmentModes.find((x) => x.id === ship.mode)?.mode} Option {optionIndex + 1}</p>
                  {/* Map through each service */}
                  {option?.services.map((service, serviceIndex) => (
                    <div key={serviceIndex} className="mb-6">
                      {/* Origin and Destination Details */}



                      {option.services.length > 1 && <div className="bg-gray-100 p-4 rounded-md grid gap-3 grid-cols-5 mb-4">
                        <div className="flex flex-col gap-1">
                          <div className="text-gray-400 text-xs">Origin</div>
                          <div className="text-xs">{`${service?.originName || 'N/A'}`}</div>

                        </div>
                        <div className="flex flex-col gap-1">
                          <div className="text-gray-400 text-xs">Destination</div>
                          <div className="text-xs">{`${service?.destinationName || 'N/A'}`}</div>

                        </div>
                        <div className="flex flex-col gap-1">
                          <div className="text-gray-400 text-xs">Gross Weight</div>
                          <div className="text-xs">{`${option.cargoDetailsOptions?.grossWeight || 0}`}</div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <div className="text-gray-400 text-xs">Chg. Weight</div>
                          <div className="text-xs">{`${option.cargoDetailsOptions?.chargeWeight || 0}`}</div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <div className="text-gray-400 text-xs">Quantity</div>
                          <div className="text-xs">{`${option.cargoDetailsOptions?.quantity || 0}`}</div>
                        </div>
                      </div>}

                      {/* Service Details */}
                      <div className="bg-gray-100 p-4 rounded-md">
                        <div className="grid grid-cols-2">
                          <p className="text-gray-800 px-2 text-sm">
                            Expected Date:{" "}
                            <span className="font-medium">
                              {/* {new Date(service.expectedDate).toLocaleDateString()} */}

                            </span>
                          </p>
                        </div>
                        <div className="rounded-lg mt-3">
                          <table className="w-full bg-white">
                            <thead className="bg-gray-50 border-b-2 text-xs border-gray-200">
                              <tr>
                                <th className="px-4 py-2 text-left font-semibold text-gray-700 uppercase tracking-wider">
                                  Service
                                </th>
                                <th className="px-4 py-2 text-left font-semibold text-gray-700 uppercase tracking-wider">
                                  Quantity
                                </th>
                                <th className="px-4 py-2 text-left font-semibold text-gray-700 uppercase tracking-wider">
                                  Price
                                </th>
                                <th className="px-4 py-2 text-left font-semibold text-gray-700 uppercase tracking-wider">
                                  Description
                                </th>
                                <th className="px-4 py-2 text-right font-semibold text-gray-700 uppercase tracking-wider">
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y text-xxs divide-gray-200">
                              {service.details?.map((x, detailIndex) => (
                                <tr key={detailIndex} className="transition-colors duration-200">
                                  <td className="px-5 py-2 whitespace-nowrap text-gray-900">
                                    {x.service}
                                  </td>
                                  <td className="px-5 py-2 whitespace-nowrap text-gray-500">
                                    {x.quantity}
                                  </td>
                                  <td className="px-5 py-2 whitespace-nowrap text-gray-500">
                                    {x.price.toLocaleString()} {quotation.currencyCode}
                                  </td>
                                  <td className="px-5 py-2 text-gray-500">{x.description}</td>
                                  <td className="px-5 py-2 whitespace-nowrap text-right font-medium text-gray-900">
                                    {(x.price * x.quantity).toLocaleString()} {quotation.currencyCode}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="flex justify-between px-2 mt-5">
                          <p className="font-semibold text-gray-800 mb-2">
                            Comment: {service.comment}
                          </p>
                          <div className="text-right">
                            Total: &nbsp;
                            <span className="text-lg font-medium">
                              {service.details?.reduce(
                                (a, b) => a + b.price * b.quantity,
                                0
                              ) || 0}{" "}
                              {quotation?.currencyCode}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  
                </div>
                
              ))}

            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-2  top-2 right-5 bg-white py-2 px-2">
        <ReactToPrint
          trigger={() => (
            <button
              type="button"
              className="inline-flex w-40 float-right justify-center items-center gap-2 py-1 px-2 border-2 border-sky-600 shadow-sm text-xs font-semibold rounded-md bg-sky-600 text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
              onClick={printContent}
            >
              <img src="print.svg" className="h-5" /> Print
            </button>
          )}
          pageStyle={`
            @media print {
              @page {
                size: portrait;
                margin: 30px !important;
                padding: 30px !important
                margin-top:30px !important
                
                background-color: rgb(245 245 245) !important;
              }
              body {
                margin: 0;
                background-color:rgb(245 245 245/1) !important;
                -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
                print-color-adjust: exact !important; /* Firefox and Edge */
            }
               .noBorder{
                    border: none !important;
                    min-height: 1060px;
                }
            }
                @media print {
  .print-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 12px;
    
    padding: 8px 0;
  }

  .pageNumber::after {
    content: counter(page);
  }

  .totalPages::after {
    content: counter(pages);
  }

  body {
    counter-reset: page;
  }
                
          `}
          content={() => componentRef.current}
        />

        <button
          type="button"
          className="inline-flex float-right justify-center items-center gap-2 py-1 px-2 border-2 border-black shadow-sm text-xs font-semibold rounded-md bg-black text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
          onClick={() => closeModal()}
        >
          <img src="x.svg" className="h-5" /> Close
        </button>
      </div>
    </div>
  );
};

export default QuotationDocument;
