import React from "react";

const QuotationStats = ({ statusData }) => {
  // Map API status labels to UI configuration
  const statusConfig = {
    "Total Quotations": {
      bgColor: "bg-purple-100",
      icon: "page_dollar.svg",
    },
    Approved: {
      bgColor: "bg-green-100",
      icon: "round_tick.svg",
    },
    Rejected: {
      bgColor: "bg-red-100",
      icon: "red_x.svg",
    },
    Draft: {
      bgColor: "bg-yellow-100",
      icon: "progress.svg",
    },
    Submitted: {
      bgColor: "bg-indigo-100",
      icon: "pending.svg",
    },
  };

  return (
    <div className="flex gap-2 w-full justify-between pb-4">
      {statusData?.map((item, index) => (
        <div
          key={index}
          className="flex w-1/5 items-center space-x-2 p-2 bg-white rounded-lg shadow"
        >
          <div
            className={`flex items-center justify-center w-8 h-8 ${
              statusConfig[item.label]?.bgColor || "bg-gray-100"
            } rounded-md`}
          >
            <img
              src={statusConfig[item.label]?.icon || "default.svg"}
              className="h-5"
              alt={`${item.label} icon`}
            />
          </div>
          <div>
            <p className="text-sm font-semibold">{item.count}</p>
            <p className="text-gray-500 text-xxs">{item.label}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuotationStats;
