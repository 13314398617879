import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { XIcon } from "@heroicons/react/outline";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { BASE_URL } from "../../../constants";
import SlidingPane from "react-sliding-pane";
import { filterBy } from "@progress/kendo-data-query";

const PartySlider = ({
  open,
  setOpen,
  selectedEntity,
  onCreate,
  onUpdate,
  setSelectedEntity,
}) => {
  const [formState, setFormState] = useState(false);
  const [countries, setCountries] = useState([]);
  const [party, setParty] = useState();
  const [cities, setCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);

  useEffect(() => {
    axios.get(`${BASE_URL}/Lookup/Countries?searchText=~ALL`).then((res) => {
      setCountries(res.data);
      setFilteredCountries(res.data);
    });
    axios.get(`${BASE_URL}/City/getallcity?searchText=~ALL`).then((res) => {
      setCities(res.data);
      setFilteredCities(res.data);
    });
  }, []);

  useEffect(() => {
    setParty(selectedEntity);
  }, [selectedEntity]);

  const onChange = (e) => {
    setParty({ ...party, [e.target.name]: e.target.value });
  };

  const FilterData = (filter, compoBoxData) => {
    const data = compoBoxData.slice();
    return filterBy(data, filter);
  };

  const filterComboBoxData = (filter, type) => {
    let searchText;
    if (filter.value === "") searchText = "~ALL";
    else searchText = filter.value;
    switch (type) {
      case "country":
        setFilteredCountries(FilterData(filter, countries));
        break;
      case "city":
        axios
          .get(`${BASE_URL}/City/getallcity?searchText=${searchText}`)
          .then((res) => {
            setCities(res.data);
            setFilteredCities(res.data);
          });
        break;
      default:
        break;
    }
  };

  // const CountryFilterChange = (e) => {
  //   setFilteredCountries(filterData(e.filter, "country"));
  // };
  // const CityFilterChange = () => {
  // };

  const onCountryChange = (e) => {
    setParty({
      ...party,
      country: e.value,
      countryCode: e.value.code,
      countryName: e.value.name,
    });
  };
  const onCityChange = (e) => {
    setParty({
      ...party,
      city: e.value,
      cityCode: e.value.code,
      cityName: e.value.name,
    });
  };
  const onCheckBox = (e) => {
    setParty({ ...party, [e.target.name]: e.target.checked });
  };

  const onclose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    setFormState(true);
    if (party.id !== 0) {
      const res = await axios.put(`${BASE_URL}/Party`, party);
      if (res.status === 200) {
        toast.success("Party Details updated Successfully!");
        onUpdate(party);
        setFormState(false);
      } else {
        toast.error("An error occured while updating Party!");
        setFormState(false);
      }
    } else {
      try {
        const res = await axios.post(`${BASE_URL}/Party`, party);
        onCreate(party, res.data);
        const data = { ...party, id: res.data };
        setParty(data);
        setFormState(false);
        toast.success("Party Added Successfully!");
      } catch (e) {
        setFormState(false);
        if (e.response.status === 400) {
          toast.error(e.response.data);
        } else {
          toast.error("An error occured while creating Party!");
        }
      }
    }
  };

  return (
    <SlidingPane
      closeIcon={
        <button
          type="button"
          className="rounded-md text-black hover:text-white focus:outline-none focus:ring-2 focus:ring-white absolute top-0"
        >
          <span className="sr-only">Close panel</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      }
      hideHeader
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      from="right"
      width="680px"
    >
      <div className="relative inset-0 overflow-hidden">
        <div className="w-screen max-w-2xl  bg-white shadow-xl h-[87vh] overflow-y-scroll">
          <div className="px-4 py-4 bg-indigo-50 sm:px-6 sticky top-0">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1 text-2xl">
                {party?.id !== 0 ? "Edit Party" : "New Party"}
              </div>
              <div className="h-7 flex items-center">
                <button
                  type="button"
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>

          <form className="h-full flex flex-col">
            <div className="flex-1">
              <div className="py-4 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                  <div>
                    <label
                      htmlFor="name"
                      className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      Code
                      <span className="block text-red-500 mt-0.5 pl-1">*</span>
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      name="code"
                      id="code"
                      required
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md uppercase"
                      onChange={onChange}
                      value={party?.code}
                    />
                  </div>
                </div>

                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                  <div>
                    <label
                      htmlFor="name"
                      className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      Name
                      <span className="block text-red-500 mt-0.5 pl-1">*</span>
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      required
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md uppercase"
                      onChange={onChange}
                      value={party?.name}
                    />
                  </div>
                </div>

                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 h-28">
                  <div>
                    <label
                      htmlFor="name"
                      className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      Address
                    </label>
                  </div>
                  <div className="mt-1 h-9 w-full  col-span-2">
                    <textarea
                      rows={3}
                      name="address"
                      id="address"
                      value={party?.address}
                      onChange={onChange}
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md uppercase"
                    />
                  </div>
                </div>

                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                  <div>
                    <label
                      htmlFor="name"
                      className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      Country
                      <span className="block text-red-500 mt-0.5 pl-1">*</span>
                    </label>
                  </div>
                  <div className="mt-1 w-full col-span-2" id="compo">
                    <ComboBox
                      data={filteredCountries}
                      textField="name"
                      dataItemKey="code"
                      id="countrycode"
                      name="country"
                      suggest={true}
                      clearButton={true}
                      filterable={true}
                      onFilterChange={(e) =>
                        filterComboBoxData(e.filter, e.target.name)
                      }
                      className=" block focus:ring-blue-500 focus:border-blue-500 w-full h-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      onChange={onCountryChange}
                      value={party?.country}
                    />
                  </div>
                </div>

                {/* {party?.agent && (
                  <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                    <div>
                      <label
                        htmlFor="name"
                        className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                      >
                        City
                        <span className="block text-red-500 mt-0.5 pl-1">
                          *
                        </span>
                      </label>
                    </div>
                    <div className="mt-1 w-full col-span-2" id="compo">
                      <ComboBox
                        data={filteredCities}
                        textField="name"
                        dataItemKey="code"
                        id="citycode"
                        name="city"
                        suggest={true}
                        clearButton={true}
                        filterable={true}
                        onFilterChange={(e) =>
                          filterComboBoxData(e.filter, e.target.name)
                        }
                        className="block focus:ring-blue-500 focus:border-blue-500 w-full h-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onChange={onCityChange}
                        value={party?.city}
                      />
                    </div>
                  </div>
                )} */}

                <div className="flex justify-center space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-20 sm:px-6 sm:py-4">
                  <div class="flex justify-between form-check form-check-inline">
                    <label
                      className="form-check-label inline-block text-sm font-medium text-gray-900"
                      for="inlineCheckbox1"
                    >
                      Customer
                    </label>
                    <input
                      name="customer"
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      id="inlineCheckbox1"
                      onChange={onCheckBox}
                      checked={party?.customer}
                      value={party?.customer}
                    ></input>
                  </div>
                  <div class="flex justify-between form-check form-check-inline">
                    <label
                      className="form-check-label inline-block text-sm font-medium text-gray-900"
                      for="inlineCheckbox1"
                    >
                      Supplier
                    </label>
                    <input
                      name="supplier"
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      id="inlineCheckbox1"
                      onChange={onCheckBox}
                      checked={party?.supplier}
                      value={party?.supplier}
                    ></input>
                  </div>
                </div>

                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-20 sm:px-6 sm:py-4">
                  <div class="flex justify-between form-check form-check-inline">
                    <label
                      className="form-check-label inline-block text-sm font-medium text-gray-900"
                      for="inlineCheckbox1"
                    >
                      Agent
                    </label>
                    <input
                      name="agent"
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      id="inlineCheckbox1"
                      checked={party?.agent}
                      onChange={onCheckBox}
                      value={party?.agent}
                    ></input>
                  </div>
                  <div class="flex justify-between form-check form-check-inline">
                    <label
                      className="form-check-label inline-block text-sm font-medium text-gray-900"
                      for="inlineCheckbox1"
                    >
                      Shipper
                    </label>
                    <input
                      name="shipper"
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      id="inlineCheckbox1"
                      checked={party?.shipper}
                      onChange={onCheckBox}
                      value={party?.shipper}
                    ></input>
                  </div>
                </div>

                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-20 sm:px-6 sm:py-4">
                  <div class="flex justify-between form-check form-check-inline">
                    <label
                      className="form-check-label inline-block text-sm font-medium text-gray-900"
                      for="inlineCheckbox1"
                    >
                      Consignee
                    </label>
                    <input
                      name="consignee"
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      id="inlineCheckbox1"
                      onChange={onCheckBox}
                      checked={party?.consignee}
                      value={party?.consignee}
                    ></input>
                  </div>
                  <div class="flex justify-between form-check form-check-inline">
                    <label
                      className="form-check-label inline-block text-sm font-medium text-gray-900"
                      for="inlineCheckbox1"
                    >
                      Notify Party
                    </label>
                    <input
                      name="notifyParty"
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      id="inlineCheckbox1"
                      onChange={onCheckBox}
                      checked={party?.notifyParty}
                      value={party?.notifyParty}
                    ></input>
                  </div>
                </div>
                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-20 sm:px-6 sm:py-4">
                  <div class="flex justify-between form-check form-check-inline">
                    <label
                      className="form-check-label inline-block text-sm font-medium text-gray-900"
                      for="inlineCheckbox1"
                    >
                      Active
                    </label>
                    <input
                      name="active"
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      id="inlineCheckbox1"
                      onChange={onCheckBox}
                      checked={party?.active}
                      value={party?.active}
                    ></input>
                  </div>
                </div>
                <div className="fixed bottom-0 w-full px-4 border-t border-gray-200 py-4 sm:px-6">
                  <div className="space-x-3 flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={onSubmit}
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                      disabled={
                        party?.code === "" ||
                        party?.name === "" ||
                        party?.country === ""
                      }
                    >
                      {formState && (
                        <svg
                          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )}
                      {party?.id ? "Update" : "Create"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ToastContainer />
      </div>
    </SlidingPane>
  );
};

export default PartySlider;
