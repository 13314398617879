import React, { useContext } from "react";
import {
  AutoComplete,
  ComboBox,
  DropDownList,
} from "@progress/kendo-react-dropdowns";
import "../../index.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../constants";
// import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DatePicker } from "antd";
import { debounce } from "lodash";
import AppContext from "../../context/app-context";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the utc plugin

dayjs.extend(utc); // Extend dayjs with the utc plugin

const JobForm = ({ setJob, job, OnSubmit, setModified, formDisabled }) => {
  const authCtx = useContext(AppContext);
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [filteredShipper, setFilteredShipper] = useState([]);
  const [filteredOriginPort, setFilteredOriginPort] = useState();
  const [currentWarehouseCountry, setCurrentWarehouseCountry] = useState();
  const [filteredDestinWarehouse, setFilteredDestinWarehouse] = useState();
  const [filteredDestinPort, setFilteredDestinPort] = useState();
  const [originAgent, setOriginAgent] = useState([]);
  const [destinationAgent, setDestinationAgent] = useState([]);

  const loadData = async () => {
    const profile = authCtx.profile;
    let res = await axios.get(`${BASE_URL}/Lookup/Customers?searchText=~ALL`);
    setFilteredCustomer(res.data);
    res = await axios.get(`${BASE_URL}/Lookup/parties?searchText=~ALL`);
    setFilteredShipper(res.data);
    res = await axios.get(
      `${BASE_URL}/Lookup/Ports?searchText=~ALL&take=40&countryCode=${
        profile.role === 300 ? profile.warehouse?.countryCode : null
      }`
    );
    setFilteredDestinPort(res.data);
    const warehousecountry = job.poes
      ? job.poes.length > 0 && job.poes[0].package?.currentWarehouseCountry
      : null;
    if (warehousecountry !== undefined && warehousecountry !== null) {
      setCurrentWarehouseCountry(warehousecountry);
    }
  };

  const loadOriginPortData = async () => {
    const profile = authCtx.profile;

    let res = await axios.get(
      `${BASE_URL}/Lookup/Ports?searchText=~ALL&take=10&countryCode=${
        profile.role === 300
          ? profile.warehouse?.countryCode
          : job.jobType === 400
          ? null
          : currentWarehouseCountry
      }`
    );
    setFilteredOriginPort(res.data);
  };

  useEffect(() => {
    loadOriginPortData();
  }, [currentWarehouseCountry]);

  const filterList = debounce(async (e) => {
    const profile = authCtx.profile;

    let res;
    let searchText;
    if (e.filter.value === "") searchText = "~ALL";
    else searchText = e.filter.value;
    switch (e.target.name) {
      case "customer":
        res = await axios.get(
          `${BASE_URL}/Lookup/Customers?searchText=${searchText}`
        );
        setFilteredCustomer(res.data);
        break;
      case "party":
        res = await axios.get(
          `${BASE_URL}/Lookup/Parties?searchText=${searchText}`
        );
        setFilteredShipper(res.data);
        break;
      case "originPort":
        if (job.jobType === 400)
          res = await axios.get(
            `${BASE_URL}/Lookup/Ports?searchText=${searchText}&take=40&countryCode=${
              profile.role === 300 ? profile.warehouse?.countryCode : null
            }`
          );
        else
          res = await axios.get(
            `${BASE_URL}/Lookup/Ports?searchText=${searchText}&take=10&countryCode=${
              profile.role === 300
                ? profile.warehouse?.countryCode
                : currentWarehouseCountry
            }`
          );
        setFilteredOriginPort(res.data);
        break;
      case "destinPort":
        res = await axios.get(
          `${BASE_URL}/Lookup/Ports?searchText=${searchText}&take=40&countryCode=${null}`
        );
        setFilteredDestinPort(res.data);
        break;
      case "originAgent":
        res = await axios.get(
          `${BASE_URL}/Lookup/Agents?searchText=${searchText}&country=${job.originCountryCode}&take=10`
        );
        setOriginAgent(res.data);
        break;
      case "destinAgent":
        res = await axios.get(
          `${BASE_URL}/Lookup/Agents?searchText=${searchText}&country=${job.destinCountryCode}&take=10`
        );
        setDestinationAgent(res.data);
        break;
      case "destinWarehouse":
        res = await axios.get(
          `${BASE_URL}/Lookup/Warehouses?searchText=${searchText}&take=10&countryCode=${job.destinCountryCode}`
        );
        setFilteredDestinWarehouse(res.data);
        break;
      default:
        break;
    }
  }, 300);

  const loadOriginData = async () => {
    if (job.originCountryCode !== undefined && job.originCountryCode !== "") {
      let res = await axios.get(
        `${BASE_URL}/Lookup/Agents?searchText=~ALL&country=${job.originCountryCode}&take=10`
      );
      setOriginAgent(res.data);
    }
  };

  const loadDestinData = async () => {
    if (job.destinCountryCode !== undefined && job.destinCountryCode !== "") {
      let res = await axios.get(
        `${BASE_URL}/Lookup/Agents?searchText=~ALL&country=${job.destinCountryCode}&take=10`
      );
      setDestinationAgent(res.data);
      res = await axios.get(
        `${BASE_URL}/Lookup/Warehouses?searchText=~ALL&take=10&countryCode=${job.destinCountryCode}`
      );
      setFilteredDestinWarehouse(res.data);
    }
  };

  useEffect(() => {
    loadData();
  }, [job.id]);

  useEffect(() => {
    loadOriginData();
  }, [job.originPortCode]);

  useEffect(() => {
    loadDestinData();
  }, [job.destinationPortCode]);

  const handleChange = (e) => {
    setModified(true);
    setJob({ ...job, [e.target.name]: e.target.value });
  };

  console.log(job.eta, job.etd);

  const handleDateChange = (date, field) => {
    if (date) {
      const selectedDate = dayjs(date).utc(); // Ensure it's in UTC
      selectedDate.set("hour", 12); // Adjust time to noon, if needed

      setJob({
        ...job,
        [field]: selectedDate.toDate(), // Save JavaScript Date object in state
      });
    } else {
      setJob({
        ...job,
        [field]: null, // Handle case when the user clears the input
      });
    }
  };

  const onWarehouseChange = (e) => {
    if (e.value) {
      setModified(true);
      setJob((es) => ({
        ...es,
        destinWarehouseId: e.value.id,
        destinationWarehouse: e.value,
      }));
    }
  };
  const OnShipperChange = (e) => {
    setModified(true);
    setJob({
      ...job,
      shipperCode: e.value.code,
      shipper: e.value,
      shipperName: e.value.name,
      shipperAddress: e.value.address,
    });
  };

  const OnConsigneeChange = (e) => {
    setModified(true);
    setJob({
      ...job,
      consigneeId: e.value.id,
      consigneeName: e.value.name,
      consignee: e.value,
      consigneeAddress: e.value.address,
    });
  };

  const onCustomerChange = (e) => {
    setModified(true);
    setJob({ ...job, customer: e.value, customerId: e.value.id });
  };

  const onOriginPortChange = async (e) => {
    setModified(true);
    const jobState = {
      ...job,
      originPort: e.value,
      originPortName: e.value.name,
      originPortCode: e.value.code,
      origin: e.value.cityId != null ? e.value.cityName : e.value.countryName,
      originCountryCode: e.value.countryCode,
    };
    setJob(jobState);
  };

  const onDestinationPortChange = async (e) => {
    setModified(true);
    const jobState = {
      ...job,
      destinPort: e.value,
      destinationPortName: e.value.name,
      destinationPortCode: e.value.code,
      destination:
        e.value.cityId != null ? e.value.cityName : e.value.countryName,
      destinCountryCode: e.value.countryCode,
    };
    setJob(jobState);
  };

  const onPartyChange = (e) => {
    setModified(true);
    setJob({
      ...job,
      notificationParty: e.value,
      notificationPartyName: e.value.name,
      notificationPartyCode: e.value.id + "",
      notifyPartyAddress: e.value.address,
    });
  };

  const onOriginAgentChange = (e) => {
    setModified(true);
    setJob({
      ...job,
      originAgent: e.value,
      originAgentName: e.value.name,
      originAgentId: e.value.id,
    });
  };

  const onDestinationAgentChange = (e) => {
    setModified(true);
    setJob({
      ...job,
      destinationAgent: e.value,
      dfestinationAgentName: e.value.name,
      destinationAgentId: e.value.id,
    });
  };

  return (
    <div className="h-full">
      <form onSubmit={OnSubmit} className="relative h-screen w-full">
        <div className="flex w-full h-2/3 lg:overflow-y-auto">
          <div className="px-5 grow w-4/6">
            <div className="flex mt-5 gap-4">
              <div className="flex-col w-1/2">
                <div>
                  <label className="text-sm font-normal">Customer</label>
                </div>
                <div className="mt-1 bg-white" id="poCompo">
                  <ComboBox
                    data={filteredCustomer}
                    textField="name"
                    name="customer"
                    dataItemKey="id"
                    className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                    suggest={true}
                    clearButton={true}
                    filterable={true}
                    onFilterChange={filterList}
                    placeholder="Customer Name"
                    value={job?.customer}
                    onChange={onCustomerChange}
                    disabled={formDisabled}
                  />
                </div>
              </div>
              <div className="flex-col w-1/2">
                <label htmlFor="shipmentType" className="font-normal text-sm">
                  Shipment
                </label>
                <div class="inline-flex rounded group w-full mt-1 h-8 border-sky-600 border">
                  <button
                    type="button"
                    className={`bg-white text-xs font-medium text-center w-1/5 rounded-tl rounded-bl ${
                      job.mot === 100 ? "bg-sky-600 text-white" : "text-black"
                    }`}
                    onClick={() => setJob({ ...job, mot: 100 })}
                    disabled={formDisabled}
                  >
                    Air
                  </button>
                  <button
                    type="button"
                    className={`bg-white text-xs font-medium w-1/5 text-center ${
                      job.mot === 200 ? "bg-sky-600 text-white" : "text-black"
                    }`}
                    onClick={() => setJob({ ...job, mot: 200 })}
                    disabled={formDisabled}
                  >
                    Sea
                  </button>
                  <button
                    type="button"
                    className={`bg-white text-xs font-medium text-center w-1/5 ${
                      job.mot === 300 ? "bg-sky-600 text-white" : "text-black"
                    }`}
                    onClick={() => setJob({ ...job, mot: 300 })}
                    disabled={formDisabled}
                  >
                    Road
                  </button>
                  <button
                    type="button"
                    className={`bg-white text-xs font-medium w-1/5 text-center ${
                      job.mot === 400 ? "bg-sky-600 text-white" : "text-black"
                    }`}
                    onClick={() => setJob({ ...job, mot: 400 })}
                    disabled={formDisabled}
                  >
                    Courier
                  </button>
                  <button
                    type="button"
                    className={`bg-white text-xs font-medium w-1/5 text-center rounded-tr rounded-br ${
                      job.mot === 500 ? "bg-sky-600 text-white" : "text-black"
                    }`}
                    onClick={() => setJob({ ...job, mot: 500 })}
                    disabled={formDisabled}
                  >
                    Others
                  </button>
                </div>
              </div>
            </div>
            <div className="flex mt-3 gap-4">
              <div className="flex-col w-1/2">
                <div className="flex">
                  <label className="text-sm font-normal">Origin Port</label>
                  <span className="block text-red-500 pl-1">*</span>
                </div>
                <div className="mt-1 w-full" id="poCompo">
                  <ComboBox
                    data={filteredOriginPort}
                    dataItemKey="code"
                    textField="name"
                    name="originPort"
                    value={job.originPort}
                    className="w-full h-8 text-xs text-neutral-700 rounded-tl-none"
                    placeholder="Origin Port"
                    suggest={true}
                    clearButton={true}
                    filterable={true}
                    onFilterChange={filterList}
                    onChange={onOriginPortChange}
                    disabled={formDisabled}
                  />
                </div>
              </div>
              <div className="flex-col w-1/2">
                <div className="flex">
                  <label className="text-sm font-normal">
                    Destination Port
                  </label>
                  <span className="block text-red-500 pl-1">*</span>
                </div>
                <div className="mt-1 w-full" id="poCompo">
                  <ComboBox
                    data={filteredDestinPort}
                    dataItemKey="code"
                    textField="name"
                    name="destinPort"
                    value={job.destinPort}
                    className="w-full h-8 text-xs text-neutral-700 rounded-tl-none"
                    placeholder="Destination Port"
                    suggest={true}
                    clearButton={true}
                    filterable={true}
                    onFilterChange={filterList}
                    onChange={onDestinationPortChange}
                    disabled={formDisabled}
                  />
                </div>
              </div>
            </div>
            <div className="flex mt-3 gap-4">
              <div className="flex-col w-1/2">
                <div>
                  <label className="text-sm font-normal">Origin</label>
                </div>
                <div className="mt-1">
                  <input
                    type="text"
                    name="origin"
                    className="w-full h-8 rounded-md border-zinc-300 text-sm font-normal text-neutral-700 disabled:bg-slate-200"
                    placeholder="Origin"
                    value={job?.origin}
                    disabled
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex-col w-1/2">
                <div>
                  <label className="text-sm font-normal">Destination</label>
                </div>
                <div className="mt-1 ">
                  <input
                    type="text"
                    name="destination"
                    className="w-full h-8 rounded-md border-zinc-300 text-sm font-normal text-neutral-700 disabled:bg-slate-200"
                    placeholder="Destination"
                    value={job?.destination}
                    disabled
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex mt-3 gap-4">
              <div className="flex-col w-1/2">
                <div>
                  <label className="text-sm font-normal">Origin Agent</label>
                </div>
                <div className="mt-1" id="poCompo">
                  <ComboBox
                    data={originAgent}
                    textField="name"
                    name="originAgent"
                    className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                    placeholder="Origin Agent"
                    suggest={true}
                    clearButton={true}
                    value={job?.originAgent}
                    filterable={true}
                    onFilterChange={filterList}
                    onChange={onOriginAgentChange}
                    disabled={formDisabled}
                  />
                </div>
              </div>

              <div className="flex-col w-1/2">
                <div>
                  <label className="text-sm font-normal">
                    Destination Agent
                  </label>
                </div>
                <div className="mt-1" id="poCompo">
                  <ComboBox
                    data={destinationAgent}
                    textField="name"
                    name="destinAgent"
                    className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                    placeholder="Destination Agent"
                    suggest={true}
                    clearButton={true}
                    filterable={true}
                    onFilterChange={filterList}
                    value={job?.destinationAgent}
                    onChange={onDestinationAgentChange}
                    disabled={formDisabled}
                  />
                </div>
              </div>
            </div>

            <div className="flex mt-3 gap-4">
              <div className="flex-col w-1/2">
                <div>
                  <label className="text-sm font-normal">Shipper</label>
                </div>
                <div className="mt-1 bg-white" id="poCompo">
                  <ComboBox
                    data={filteredShipper}
                    dataItemKey="code"
                    textField="name"
                    name="party"
                    className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                    placeholder="Shipper"
                    suggest={true}
                    clearButton={true}
                    filterable={true}
                    onFilterChange={filterList}
                    value={job?.shipper}
                    onChange={OnShipperChange}
                    disabled={formDisabled}
                  />
                </div>
              </div>
              <div className="flex-col w-1/2">
                <div>
                  <label className="text-sm font-normal">Consignee</label>
                </div>
                <div className="mt-1" id="poCompo">
                  <ComboBox
                    data={filteredShipper}
                    textField="name"
                    name="party"
                    className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                    placeholder="Consignee"
                    suggest={true}
                    clearButton={true}
                    filterable={true}
                    onFilterChange={filterList}
                    value={job?.consignee}
                    onChange={OnConsigneeChange}
                    disabled={formDisabled}
                  />
                </div>
              </div>
            </div>
            <div className="flex mt-3 gap-4 w-full">
              <div className="flex-col w-1/2">
                <div>
                  <label className="text-sm font-normal">Shipper Address</label>
                </div>
                <div className="mt-1">
                  <input
                    type="text"
                    name="shipperAddress"
                    className="w-full h-8 rounded-md border-zinc-300 text-sm font-normal text-neutral-700"
                    placeholder="Shipper Address"
                    value={job?.shipperAddress}
                    onChange={handleChange}
                    disabled={
                      job.shipper === undefined ? true : false || formDisabled
                    }
                  ></input>
                </div>
              </div>
              <div className="flex-col w-1/2">
                <div>
                  <label className="text-sm font-normal">
                    Consignee Address
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    type="text"
                    name="consigneeAddress"
                    className="w-full h-8 rounded-md border-zinc-300 text-sm font-normal  text-neutral-700"
                    placeholder="Consignee Address"
                    value={job?.consigneeAddress}
                    onChange={handleChange}
                    disabled={
                      job.consignee === undefined ? true : false || formDisabled
                    }
                  ></input>
                </div>
              </div>
            </div>
            <div className="mt-3 mb-6">
              <div>
                <label className="text-sm font-normal">Remarks</label>
              </div>
              <div className="mt-1">
                <textarea
                  rows={3}
                  name="remarks"
                  id="remarks"
                  value={job?.remarks}
                  onChange={handleChange}
                  disabled={formDisabled}
                  className="block w-full shadow-sm sm:text-sm font-normal text-neutral-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
          <div className="w-2/6 h-full flex flex-col gap-2 grow mt-12 mr-4">
            {job.mot ? (
              <div className="border border-dashed pt-5 border-sky-600 w-full px-5 pb-5 rounded-md relative">
                <label
                  htmlFor="supplierId"
                  className="block absolute px-2 -ml-2 border border-dashed border-sky-600 rounded-md -top-3 bg-slate-100 text-black text-sm font-normal"
                >
                  Shipment Details
                </label>
                {job.jobType && job.jobType === 100 && (
                  <div className="flex w-full">
                    <div class="inline-flex rounded group w-full h-8 border border-zinc-300">
                      <button
                        type="button"
                        className={`bg-white text-xs font-medium text-center w-1/2 rounded-tl rounded-bl ${
                          job.whShipmentMode === "import"
                            ? "bg-sky-600 text-white"
                            : "text-black"
                        }`}
                        disabled={formDisabled}
                        onClick={() =>
                          setJob({ ...job, whShipmentMode: "import" })
                        }
                      >
                        Import
                      </button>
                      <button
                        type="button"
                        className={`bg-white text-xs font-medium w-1/2 text-center rounded-tr rounded-br ${
                          job.whShipmentMode === "export"
                            ? "bg-sky-600 text-white"
                            : "text-black"
                        }`}
                        disabled={formDisabled}
                        onClick={() =>
                          setJob({ ...job, whShipmentMode: "export" })
                        }
                      >
                        Export
                      </button>
                    </div>
                  </div>
                )}
                <div className="flex-col w-full">
                  <div>
                    <label className="text-sm font-normal">
                      {job.mot && job.mot === 100
                        ? "AWB No"
                        : job.mot === 200
                        ? "B/L No"
                        : job.mot === 300
                        ? "Customs Doc No"
                        : job.mot === 400
                        ? "AWB NO"
                        : "Reference Number"}
                    </label>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="awb"
                      className="w-full h-8 rounded-md text-sm font-normal text-neutral-700 border-zinc-300"
                      placeholder={
                        job.mot && job.mot === 100
                          ? "AWB No"
                          : job.mot === 200
                          ? "B/L No"
                          : job.mot === 300
                          ? "Customs Doc No"
                          : job.mot === 400
                          ? "AWB NO"
                          : "Reference Number"
                      }
                      disabled={formDisabled}
                      value={job?.awb}
                      onChange={handleChange}
                    ></input>
                  </div>
                </div>
                {job.mot && job.mot === 100 ? (
                  <div className="flex-col w-full">
                    <div>
                      <label className="text-sm font-normal">AirLine</label>
                    </div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="airLine"
                        className="w-full h-8 rounded-md text-sm font-normal text-neutral-700 border-zinc-300"
                        placeholder="AWB/BL NO"
                        value={job?.airLine}
                        onChange={handleChange}
                        disabled={formDisabled}
                      ></input>
                    </div>
                  </div>
                ) : job.mot === 200 ? (
                  <div className="flex-col w-full">
                    <div>
                      <label className="text-sm font-normal">
                        Carrier/Shipping Line
                      </label>
                    </div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="shippingLine"
                        className="w-full h-8 rounded-md text-sm font-normal text-neutral-700 border-zinc-300"
                        placeholder="Carrier/Shipping Line"
                        value={job?.shippingLine}
                        onChange={handleChange}
                        disabled={formDisabled}
                      ></input>
                    </div>
                  </div>
                ) : job.mot === 300 ? (
                  <div className="flex-col w-full">
                    <div>
                      <label className="text-sm font-normal">Truck No</label>
                    </div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="truckNo"
                        className="w-full h-8 rounded-md text-sm font-normal text-neutral-700 border-zinc-300"
                        placeholder="Truck No"
                        value={job?.truckNo}
                        onChange={handleChange}
                        disabled={formDisabled}
                      ></input>
                    </div>
                  </div>
                ) : job.mot === 400 ? (
                  <div className="flex-col w-full">
                    <div>
                      <label className="text-sm font-normal">Carrier</label>
                    </div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="carrier"
                        className="w-full h-8 rounded-md text-sm font-normal text-neutral-700 border-zinc-300"
                        placeholder="Carrier"
                        value={job?.carrier}
                        onChange={handleChange}
                        disabled={formDisabled}
                      ></input>
                    </div>
                  </div>
                ) : job.mot === 500 ? (
                  <div className="flex-col w-full">
                    <div>
                      <label className="text-sm font-normal">Remarks</label>
                    </div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="remarks"
                        className="w-full h-8 rounded-md text-sm font-normal text-neutral-700 border-zinc-300"
                        placeholder="Remarks"
                        value={job?.remarks}
                        onChange={handleChange}
                        disabled={formDisabled}
                      ></input>
                    </div>
                  </div>
                ) : null}
                {job.mot && job.mot === 100 ? (
                  <div className="flex-col w-full">
                    <div>
                      <label className="text-sm font-normal">Flt Details</label>
                    </div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="fltDetails"
                        className="w-full h-8 rounded-md text-sm font-normal text-neutral-700 border-zinc-300"
                        placeholder="Flt Details"
                        value={job?.fltDetails}
                        onChange={handleChange}
                        disabled={formDisabled}
                      ></input>
                    </div>
                  </div>
                ) : job.mot === 200 ? (
                  <div className="flex-col w-full">
                    <div>
                      <label className="text-sm font-normal">
                        Vessel's name
                      </label>
                    </div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="seaVessel"
                        className="w-full h-8 rounded-md text-sm font-normal text-neutral-700 border-zinc-300"
                        placeholder="Vessel Name"
                        value={job?.seaVessel}
                        onChange={handleChange}
                        disabled={formDisabled}
                      ></input>
                    </div>
                  </div>
                ) : null}
                {job.mot && job.mot === 200 && (
                  <div className="flex-col w-full">
                    <div>
                      <label className="text-sm font-normal">Voyage</label>
                    </div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="voyage"
                        className="w-full h-8 rounded-md text-sm font-normal text-neutral-700 border-zinc-300"
                        placeholder="Voyage"
                        value={job?.voyage}
                        onChange={handleChange}
                        disabled={formDisabled}
                      ></input>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            {job.jobType && (job.jobType === 100 || job.jobType === 400) && (
              <div className="xl:col-span-2 sm:col-span-6 xs:col-span-6">
                <label
                  htmlFor="warehouseId"
                  className="flex text-sm font-normal text-black"
                >
                  Destination Warehouse
                  <span className="block text-red-500 pl-1">*</span>
                </label>
                <div className="mt-1 h-9" id="poCompo">
                  <ComboBox
                    data={filteredDestinWarehouse}
                    name="destinWarehouse"
                    textField="name"
                    className="sm:text-sm focus:ring-0 focus:border-0 py-2 px-3 h-full p-0"
                    dataItemKey="id"
                    placeholder="Warehouse"
                    suggest={true}
                    onChange={onWarehouseChange}
                    value={job.destinationWarehouse}
                    clearButton={true}
                    filterable={true}
                    onFilterChange={filterList}
                    disabled={formDisabled}
                  />
                </div>
              </div>
            )}
            <div className="flex-col w-full">
              <div>
                <label className="text-sm font-normal">ETD</label>
              </div>
              <div
                className="mt-1 flex h-8 border-t border-x rounded-md border-gray-300"
                id="poDateCompo"
              >
                <DatePicker
                  name="etd"
                  className="h-full w-full py-2 px-3 shadow-sm sm:text-sm border-gray-300 rounded-md"
                  placeholder="Date"
                  format="YYYY-MM-DD" // Specify your desired format
                  value={job?.etd ? dayjs(job.etd) : null}
                  onChange={(e) => handleDateChange(e, "etd")}
                  required={false}
                  disabled={formDisabled}
                />
              </div>
            </div>
            <div className="flex-col w-full">
              <div>
                <label className="text-sm font-normal">ETA</label>
              </div>
              <div
                className="mt-1 border-x border-t rounded-md border-gray-300"
                id="poDateCompo"
              >
                <DatePicker
                  name="eta"
                  className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                  placeholder="Date"
                  format="YYYY-MM-DD" // Specify your desired format
                  value={job?.eta ? dayjs(job.eta) : null}
                  onChange={(e) => handleDateChange(e, "eta")}
                  required={false}
                  disabled={formDisabled}
                />
              </div>
            </div>

            <div className="flex-col w-full">
              <div>
                <label className="text-sm font-normal">Notify Party</label>
              </div>
              <div className="mt-1" id="poCompo">
                <ComboBox
                  data={filteredShipper}
                  textField="name"
                  name="party"
                  className="w-full h-8 text-xs text-neutral-700"
                  placeholder="Notification Party"
                  suggest={true}
                  clearButton={true}
                  filterable={true}
                  onFilterChange={filterList}
                  value={job?.notificationParty}
                  onChange={onPartyChange}
                  disabled={formDisabled}
                />
              </div>
            </div>
            <div className="flex-col w-full">
              <div>
                <label className="text-sm font-normal">
                  Notify Party Address
                </label>
              </div>
              <div className="mt-1">
                <input
                  type="text"
                  name="notifyPartyAddress"
                  className="w-full h-8 rounded-md border-zinc-300 text-sm font-normal text-neutral-700"
                  placeholder="Notify Party Address"
                  value={job?.notifyPartyAddress}
                  onChange={handleChange}
                  disabled={formDisabled}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default JobForm;
