import React, { useState, useEffect, useContext } from "react";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import axios from "axios";
import { BASE_URL, jobTypes } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import AppContext from "../../context/app-context";
import KendoLoader from "../KendoLoader";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { debounce } from "lodash";
import QuotationStats from "./QuotationStats";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const columns = [
  {
    field: "quotationNo",
    title: "Quot No",
    show: true,
    width: "150",
    filterable: true,
  },
  {
    field: "customerName",
    title: "Customer",
    show: true,
    width: "auto",
    filterable: true,
  },
  {
    field: "originPortName",
    title: "Origin",
    show: true,
    width: "auto",
    filterable: true,
  },
  {
    field: "destinationPortName",
    title: "Destination",
    show: true,
    width: "auto",
    filterable: true,
  },
  // {
  //   field: "quantityWeight",
  //   title: "Quantity/Weight",
  //   show: true,
  //   width: 150,
  //   filterable: false,
  // },
  // {
  //   field: "cargoDetails.grossWeight",
  //   title: "Weight",
  //   show: true,
  //   width: "auto",
  //   filterable: false,
  // },
  // {
  //   field: "status",
  //   title: "Status",
  //   show: true,
  //   width: "auto",
  //   filterable: false,
  //   cell: (props) => {
  //     const statusStyle = {
  //       "In Progress": { backgroundColor: "#FFF3E0", color: "#FFA000" },
  //       Approved: { backgroundColor: "#E8F5E9", color: "#388E3C" },
  //       Rejected: { backgroundColor: "#FFEBEE", color: "#D32F2F" },
  //     }[props.dataItem.status] || {
  //       backgroundColor: "#E0E0E0",
  //       color: "#757575",
  //     };
  //     return (
  //       <td
  //         style={{
  //           padding: "0px 10px",
  //         }}
  //       >
  //         <div
  //           className=""
  //           style={{
  //             ...statusStyle,
  //             padding: "5px 10px",
  //             borderRadius: "5px",
  //             display: "inline-block",
  //           }}
  //         >
  //           <span
  //             style={{
  //               display: "inline-block",
  //               width: "10px",
  //               height: "10px",
  //               borderRadius: "50%",
  //               backgroundColor: statusStyle.color,
  //               marginRight: "5px",
  //             }}
  //           ></span>
  //           {props.dataItem.status}
  //         </div>
  //       </td>
  //     );
  //   },
  // },
];

export default function QuotationList() {
  let navigate = useNavigate();
  const { filterValue } = useParams();
  const defaultsearchTexts = {
    location: "~ALL",
    poNo: "~ALL",
    vesselName: "~ALL",

    supplierName: "~ALL",
    shipmentRefNo: "~ALL",
  };
  const [statusStats, setStatusStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [polist, setPoList] = useState([]);
  const [searchQuoteNo, setSearchQuoteNo] = useState();
  const [customer, setCustomer] = useState();
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [searchTexts, setSearchTexts] = useState(defaultsearchTexts);
  const [pageState, setPageState] = useState({
    sort: [{ field: "creationDate", dir: "desc" }],
    skip: 0,
    take: 10,
  });
  const [gridData, setGridData] = useState({
    sort: [{ field: "creationDate", dir: "desc" }],
    skip: 0,
    take: 10,
    //total: 0,
  });

  const [stateColumns, setStateColumns] = React.useState(columns);
  const authCtx = useContext(AppContext);
  const _export = React.useRef(null);

  const loadQuotations = async (
    customerId = null,
    searchText = null,
    customer = null,
    origin = null,
    destination = null,
    page = 1,
    pageSize = 10
  ) => {
    setLoading(true);
    let query = "";
    if (customerId) {
      query = `&customerId=${customerId}`;
    }
    if (searchText) {
      query += `&quoteNo=${searchText}`;
    }
    if (customer) {
      query += `&customer=${customer}`;
    }
    if (origin) {
      query += `&origin=${origin}`;
    }
    if (destination) {
      query += `&destination=${destination}`;
    }
    const res = await axios.get(
      `${BASE_URL}/Quotation?page=${page}&pageSize=${pageSize}${query}`
    );
    setStatusStats(res.data.status || []);
    let data = res.data.data.map((x) => ({
      ...x,
      quantityWeight: `${x.cargoDetails.quantity}/${x.cargoDetails.grossWeight}`,
      jobType: jobTypes.find((y) => y.id === x.jobType)?.name,
    }));
    setPoList(data);
    setGridData((pre) => ({
      ...pre,
      total: res.data.totalCount,
      take: res.data.pageSize,
      // skip: res.data.pageNo,
    }));
    setLoading(false);
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  const handleGridDataStateChange = (e) => {
    setPageState(e.dataState);
  };

  const [selectedState, setSelectedState] = React.useState({});
  const [selectedPos, setSelectedPos] = useState([]);
  const onSelectionChange = React.useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
      const index = selectedPos.findIndex((x) => x.id === event.dataItem.id);

      if (index >= 0) {
        setSelectedPos((selectedPos) =>
          selectedPos.filter((_, i) => i !== index)
        );
      } else {
        setSelectedPos((oldArray) => [...oldArray, event.dataItem]);
      }
    },
    [selectedState]
  );
  const onLocationChange = (e) => {
    setSearchQuoteNo(e.target.value);
  };

  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedPos(event.dataItems);
    setSelectedState(newSelectedState);
  }, []);

  const loadData = async () => {
    const profile = authCtx.profile;
    let res = await axios.get(`${BASE_URL}/Lookup/Customers?searchText=~ALL`);
    setFilteredCustomer(res.data);
  };

  const onExpandChange = (event) => {
    let newData = polist.map((item) => {
      if (item.id === event.dataItem.id) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setPoList(newData);
  };

  const filterList = debounce(async (e) => {
    const profile = authCtx.profile;

    let res;
    let searchText;
    if (e.filter.value === "") searchText = "~ALL";
    else searchText = e.filter.value;
    switch (e.target.name) {
      case "customer":
        res = await axios.get(
          `${BASE_URL}/Lookup/Customers?searchText=${searchText}`
        );
        setFilteredCustomer(res.data);
        break;
      default:
        break;
    }
  }, 300);
  const onCustomerChange = (e) => {
    setCustomer(e.value);
    loadQuotations(e.value.id);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadQuotations(customer?.id, searchQuoteNo);
  }, [searchQuoteNo, customer?.id]);

  useEffect(() => {
    console.log("searchTexts", searchTexts);
    const debouncedLoadData = debounce((data) => {
      const quoteNo = searchTexts.quotationNo;
      const customer = searchTexts.customerName;
      const origin = searchTexts.originPortName;
      const destination = searchTexts.destinationPortName;
      loadQuotations(null, quoteNo, customer, origin, destination);
    }, 300); // 300ms debounce time

    debouncedLoadData(gridData);

    return () => {
      debouncedLoadData.cancel(); // Cleanup on unmount or dependency change
    };
  }, [searchTexts]);

  const onEditClick = (e) => {
    const editedItem = e.dataItem;
    navigate(`/quotation/${editedItem.id}`);
  };

  const newQuotationClick = () => {
    navigate(`/quotation/new`);
  };

  const onGridFilter = (e) => {
    if (e.filter !== null) {
      if (e.filter && e.filter.filters && e.filter.filters[0].value !== "") {
        setSearchTexts((pre) => ({
          ...pre,
          [e.filter.filters[0].field]: e.filter.filters[0].value,
        }));
      } else {
        setSearchTexts((pre) => ({
          ...pre,
          [e.filter.filters[0].field]: "~ALL",
        }));
      }
      const state = {
        ...gridData,
        filter: { ...e.filter },
        skip: 0,
      };
      setGridData(state);
    } else {
      setSearchTexts(defaultsearchTexts);
      const state = {
        ...gridData,
        filter: [
          {
            field: "quotationNumber",
            operator: "contains",
            value: "",
          },
        ],
        skip: 0,
      };
      setGridData(state);
    }
  };

  const onPageChange = (e) => {
    const state = { ...gridData, skip: e.page.skip, take: e.page.take };
    setGridData(state);
    const pageNumber = Math.floor(state.skip / state.take) + 1;
    loadQuotations(null, null, pageNumber, state.take);
  };

  const rowRender = (trElement, props) => {
    const approved = props.dataItem.isApproved;
    const orange = { backgroundColor: "rgb(255, 165, 0, 0.40)" };
    const trProps = { style: !approved ? orange : {} };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  return (
    <div className="mx-4 my-4 sm:mx-6 lg:mx-8">
      <div className="flex mb-4 justify-between">
        <div className="flex">
          <div className="flex-col min-w-0">
            <h1 className="text-xl font-semibold leading-6 text-gray-900 sm:truncate">
              All Quotations
            </h1>
            <div className="text-xs">Logistics quotations</div>
          </div>
        </div>
        <div className="mt-4 flex sm:mt-0 sm:ml-4 gap-2 items-center">
          <div className="flex gap-2 items-center">
            <div>
              <label className="text-sm font-normal">Customer</label>
            </div>
            <div className="mt-1 bg-white" id="poCompo">
              <ComboBox
                data={filteredCustomer}
                textField="name"
                name="customer"
                dataItemKey="id"
                className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                suggest={true}
                clearButton={true}
                filterable={true}
                onFilterChange={filterList}
                placeholder="Customer Name"
                value={customer}
                onChange={onCustomerChange}
              />
            </div>
          </div>
          <div className="flex text-xs text-gray-900 font-medium items-center gap-2 relative">
            <label>Quotation</label>
            <input
              type="text"
              name="location"
              placeholder="Search Quotation Number"
              className="w-56 h-9 rounded-md placeholder:text-xs placeholder:text-gray-500 shadow-sm border border-slate-300 text-neutral-700 text-sm font-normal"
              value={searchQuoteNo}
              onChange={(e) => onLocationChange(e)}
            />
            <button
              className="absolute right-2 px-1 h-full rounded-tr-md rounded-br-md"
              type="button"
              onClick={() => {
                setSearchTexts((pre) => ({ ...pre, location: "" }));
                //loadData(searchTexts, gridData);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="gray"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* <button
            title="Export Excel"
            className="flex h-7 w-24 bg-white text-center justify-center items-center text-xs font-semibold gap-2 border border-zinc-300 rounded-sm"
            onClick={excelExport}
          >
            <img src="export.svg" alt="export" />
            Export
          </button> */}
          <button
            type="button"
            className="inline-flex h-9 w-fit px-3 items-center justify-center border-2 shadow-sm text-xs gap-2 font-semibold rounded-md text-white bg-lime-600 focus:outline-none  sm:order-1"
            onClick={newQuotationClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            Quotation
          </button>
        </div>
      </div>
      <QuotationStats statusData={statusStats} />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden relative rounded-lg shadow-[0_0_4px_rgba(0,0,0,0.1)]">
              {loading && <KendoLoader />}
              <ExcelExport data={polist} ref={_export}>
                <Grid
                  data={process(
                    polist.map((item) => ({
                      ...item,
                      [SELECTED_FIELD]: selectedState[idGetter(item)],
                    })),
                    pageState
                  )}
                  pageable={{
                    pageSizes: true,
                  }}
                  // detail={DetailComponent}
                  filterable={true}
                  sortable={true}
                  resizable={true}
                  // reorderable={true}
                  onFilterChange={onGridFilter}
                  onDataStateChange={handleGridDataStateChange}
                  onRowDoubleClick={onEditClick}
                  // rowRender={rowRender}
                  onExpandChange={onExpandChange}
                  expandField="expanded"
                  dataItemKey={DATA_ITEM_KEY}
                  onPageChange={onPageChange}
                  selectedField={SELECTED_FIELD}
                  selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "multiple",
                  }}
                  onSelectionChange={onSelectionChange}
                  onHeaderSelectionChange={onHeaderSelectionChange}
                  {...gridData}
                  style={{
                    borderRadius: "10px",
                    borderColor: "#E5E7EB",
                    fontSize: "12px",
                    fontFamily: "poppins",
                    zIndex: 0,
                    height: "65vh",
                  }}
                >
                  <GridColumn
                    filterable={false}
                    width="65px"
                    field={SELECTED_FIELD}
                    headerSelectionValue={
                      polist.findIndex(
                        (item) => !selectedState[idGetter(item)]
                      ) === -1
                    }
                  />
                  {stateColumns.map(
                    (column, idx) =>
                      column.show && (
                        <GridColumn
                          key={idx}
                          field={column.field}
                          title={column.title}
                          filterable={column.filterable}
                          cell={column.cell}
                          selectable={true}
                          width={column.width}
                          // columnMenu={(props) => (
                          //   <CustomColumnMenu
                          //     {...props}
                          //     columns={stateColumns}
                          //     onColumnsSubmit={onColumnsSubmit}
                          //   />
                          // )}
                        />
                      )
                  )}
                  <GridColumn
                    key={"action"}
                    field={""}
                    title=""
                    filterable={false}
                    cell={(props) => (
                      <td>
                        <button
                          onClick={() =>
                            navigate(`/quotation/${props.dataItem.id}`)
                          }
                          style={{
                            width: 24,
                            height: 24,
                            border: "none",
                            background: "none",
                            cursor: "pointer",
                            marginRight: "5px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                            />
                          </svg>
                        </button>
                      </td>
                    )}
                    width="80"
                  />
                </Grid>
              </ExcelExport>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
