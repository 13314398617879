import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { toast } from "react-toastify";
import { getUniqueId } from "../../constants";
import { debounce } from "lodash";

const EditableKendoTable = ({
  isApproved,
  data,
  services,
  save,
  locationId,
}) => {
  // const [data, setData] = useState([]);
  const [editingRow, setEditingRow] = useState();
  const [editID, setEditID] = useState(null);
  const [editColumnID, setEditColumnID] = useState(null);
  // const [nextRowIndex, setNextRowIndex] = useState(null);
  const comboBoxRef = useRef(null);
  const unitPriceRef = useRef(null);
  const quantityRef = useRef(null);
  const descriptionRef = useRef(null);

  const isEditing = (item) => item.index === editID;
  const gridRef = useRef(null);

  const handleGridKeyDown = (event) => {
    const { key, target, syntheticEvent } = event;

    if (syntheticEvent.key === "Tab") {
      const rowIdentifier = syntheticEvent.target.id.split("/");
      const [columnName, rowId] = rowIdentifier;

      const currentRowIndex = data.findIndex((x) => x.index === rowId);

      const nextRowIndex = currentRowIndex + 1;
      const isLastCell = columnName === "description";
      const nextRow = data[nextRowIndex];
      if (isLastCell) {
        if (currentRowIndex + 1 === data.length) {
          const hasServiceIdNullRows = !editingRow.serviceId;
          if (hasServiceIdNullRows) {
            setEditColumnID("service");
            toast.warning(
              "Please fill in all required fields before adding a new row."
            );
            setTimeout(() => {
              if (comboBoxRef.current) {
                comboBoxRef.current.focus();
              }
            }, 0);
            return;
          }
          handleSave();
          onAddRow();
          return;
        }
        handleSave(nextRow?.index);
        setEditingRow(nextRow);
      }
    }
  };

  // Handle initial data and service template changes
  // useEffect(() => {
  //   if (originData && originData.length > 0) {
  //     // Avoid state updates if data is unchanged
  //     setData((prev) =>
  //       JSON.stringify(prev) !== JSON.stringify(originData) ? originData : prev
  //     );
  //   }
  // }, [originData]);

  const debouncedSave = useRef(
    debounce((updatedData) => save(updatedData, locationId), 300)
  ).current;

  const deleteData = (index) => {
    const updatedData = data.filter((item) => item.index !== index);
    // setData(updatedData);
    save(updatedData, locationId);
  };

  const handleChange = (event, index, field) => {
    setEditingRow((prev) => {
      const updatedValue = {
        ...prev,
        [field]: event.value,
      };
      if (field === "price" || field === "quantity") {
        updatedValue.amount =
          (updatedValue.price || 0) * (updatedValue.quantity || 1);
      }
      return updatedValue;
    });

    setEditColumnID(field);
    if (field === "price" && unitPriceRef.current) {
      unitPriceRef.current.focus();
    } else if (field === "quantity" && quantityRef.current) {
      quantityRef.current.focus();
    }
    // save(updatedData);
  };

  const handleDescriptionChange = (event, index, field) => {
    setEditingRow((pre) => ({ ...pre, [field]: event.target.value }));
    setEditColumnID(field);
    setEditID(editingRow.index);
    // if (field === "description" && descriptionRef.current) {
    //   descriptionRef.current.focus();
    // }
  };

  const handleQuantityBlur = (editingRow) => {
    if (editingRow.service) {
      handleSave(true);
    } else {
      if (quantityRef.current) {
        quantityRef.current.focus();
      }
    }
  };

  useEffect(() => {
    if (editColumnID === "price" && unitPriceRef.current) {
      unitPriceRef.current.focus();
    } else if (editColumnID === "quantity" && quantityRef.current) {
      quantityRef.current.focus();
    } else if (editColumnID === "description" && descriptionRef.current) {
      descriptionRef.current.focus();
    }
  }, [editingRow]);

  const handleSave = async (nextRowIndex = null) => {
    if (!nextRowIndex) {
      setEditID(""); // Set editID to empty string to match empty serviceId
    } else {
      setEditID(nextRowIndex);
    }
    // setData((prevData) => {
    

    const updatedData = data.map((item) =>
      item.index === editingRow.index ? editingRow : item
    );
    
    save(updatedData, locationId);

    // setTimeout(() => {
    if (comboBoxRef.current) {
      comboBoxRef.current.focus();
    }
    // }, 0);
  };

  const handleCancel = (index) => {
    if (index) {
      const updatedData = data.filter(
        (item) => item.serviceId || item.index !== index
      );
      save(updatedData, locationId);
    }
    setEditID(null);
  };

  const handleEdit = (editingData) => {
    setEditID(editingData.index);
    setEditingRow(editingData);
    setTimeout(() => {
      if (comboBoxRef.current) {
        comboBoxRef.current.focus();
      }
    }, 0);
  };

  const handleAutocompleteSelect = (event, index) => {
    const selectedService = event.value;

    setEditingRow((pre) => ({
      ...pre,
      serviceId: selectedService?.id || null,
      service: selectedService?.name || null,
      description: selectedService?.description || "",
      price: selectedService?.price || 0,
      quantity: pre.quantity || 1,
      amount: selectedService
        ? (selectedService.price || 0) * (pre.quantity || 1)
        : 0,
    }));

    setEditID(index);
    if (selectedService != null)
      setTimeout(() => {
        if (unitPriceRef.current) {
          unitPriceRef.current.focus();
        }
      }, 0);
    else
      setTimeout(() => {
        if (comboBoxRef.current) {
          comboBoxRef.current.focus();
        }
      }, 0);
  };

  const handleDoubleClick = (event) => {
    const dataItem = event.dataItem;
    handleEdit(dataItem);
  };

  const handleKeyDown = (e, nextRef) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      if (unitPriceRef.current) {
        unitPriceRef.current.focus();
      }
    }
  };

  const renderServiceNameCell = (props) => {
    if (isEditing(props.dataItem)) {
      const isValid = !!editingRow?.serviceId;
      return (
        <td
          // id="poCompo"
          style={{
            padding: "0px",
            paddingLeft: "4px",
            paddingRight: "4px",
          }}
        >
          <div style={{ backgroundColor: "white", borderRadius: "6px" }}>
            <ComboBox
              tabIndex={1}
              data={services}
              rounded={"large"}
              fillMode={"outline"}
              ref={comboBoxRef}
              id={"name/" + props.dataItem.index}
              textField="name"
              valid={isValid} // Ensure valid is `false` when serviceId is null or undefined
              placeholder="Select a service"
              validationMessage="This field is required"
              required
              validityStyles
              style={{
                fontSize: "12px",
                paddingRight: "10px",
                backgroundColor: "white",
              }}
              className={`w-56 h-8  rounded-md bg-white text-sm border-zinc-300 text-xxs text-neutral-700 items-start flex `}
              onChange={(e) =>
                handleAutocompleteSelect(e, props.dataItem.index)
              }
              value={
                services.find(
                  (service) => service.id === editingRow?.serviceId
                ) || null
              }
            />
          </div>
        </td>
      );
    }

    return <td>{props.dataItem.service}</td>;
  };

  const renderDescriptionCell = (props) => {
    if (isEditing(props.dataItem)) {
      return (
        <td style={{ padding: "0px", paddingLeft: "4px", paddingRight: "4px" }}>
          <input
            type="text"
            tabIndex={4}
            id={"description/" + props.dataItem.index}
            name="description"
            ref={descriptionRef}
            disabled={!editingRow.index}
            className="w-full h-8 rounded-md border-zinc-300 text-sm font-normal text-neutral-700"
            placeholder="Description"
            value={editingRow?.description}
            onKeyDown={handleKeyDown}
            onChange={(e) =>
              handleDescriptionChange(e, props.dataItem.index, "description")
            }
          />
        </td>
      );
    }

    return <td>{props.dataItem.description}</td>;
  };

  const onAddRow = () => {
    const hasServiceIdNullRows = data.some((item) => !item.serviceId);

    if (hasServiceIdNullRows) {
      toast.warning(
        "Please fill in all required fields before adding a new row."
      );
      return;
    }

    const uniqueId = getUniqueId();
    const newRow = {
      index: uniqueId,
      serviceId: null, // Empty serviceId until service is selected
      service: "",
      description: "",
      price: 0,
      quantity: 1,
      amount: 0,
    };
    // setData((pre) => [...pre, newRow]);
    setEditingRow(newRow);
    setEditID(uniqueId);
    const updatedData = [...data, newRow];
    save(updatedData, locationId);
  };

  const AddRowCell = (props) => (
    <span style={{ paddingLeft: "0px" }}>
      <button
        disabled={isApproved}
        onClick={() => onAddRow()}
        className="flex justify-center text-xs px-2 items-center disabled:opacity-40 disabled:cursor-not-allowed py-1 bg-blue-500 text-white border rounded-md"
      >
        Add Row
      </button>
    </span>
  );

  return (
    <div
      className={`shadow overflow-hidden border-b border-t border-gray-200 sm:rounded-lg `}
    >
      {/* <div className=" flex p-2 text-xs">
        <span className="text-red-600 text-sm font-medium">*</span>
        Atleast one row shoulb be filled
      </div> */}
      <Grid
        data={data}
        editField="inEdit"
        ref={gridRef}
        onKeyDown={handleGridKeyDown}
        style={{
          borderRadius: "10px",
          borderColor: "#E5E7EB",
          fontSize: "12px",
          fontFamily: "poppins",
          zIndex: 0,
        }}
        headerCellRender={(_, props) => (
          <div className="text-neutral-700 text-sm">{props.title}</div>
        )}
        onRowDoubleClick={handleDoubleClick}
      >
        <GridColumn
          field="#"
          title="#"
          editor="numeric"
          width={50}
          editable={false}
          className="w-10 text-center"
          cell={(props) => (
            <td className="text-right !p-4">{props.dataIndex + 1}</td>
          )}
        />
        <GridColumn
          field="service"
          title="Service"
          className="text-left"
          cell={renderServiceNameCell}
        />

        <GridColumn
          field="price"
          title="Unit Price"
          width={120}
          cell={(props) => {
            return isEditing(props.dataItem) ? (
              <td
                id="poCompo"
                style={{
                  padding: "0px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <NumericTextBox
                  tabIndex={2}
                  id={"price/" + props.dataItem.index}
                  ref={unitPriceRef}
                  min={0}
                  disabled={!editingRow.serviceId}
                  className="w-full h-8 rounded-md disabled:opacity-40 border-zinc-300 text-xs text-neutral-700"
                  value={editingRow?.price}
                  onChange={(e) =>
                    handleChange(e, props.dataItem.index, "price")
                  }
                />
              </td>
            ) : (
              <td>{props.dataItem.price}</td>
            );
          }}
        />
        <GridColumn
          field="quantity"
          title="Unit"
          width={100}
          cell={(props) => {
            return isEditing(props.dataItem) ? (
              <td
                id="poCompo"
                style={{
                  padding: "0px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <NumericTextBox
                  tabIndex={3}
                  id={"quantity/" + props.dataItem.index}
                  ref={quantityRef}
                  disabled={!editingRow?.serviceId}
                  className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                  value={editingRow?.quantity}
                  min={0}
                  defaultValue={1}
                  onChange={(e) =>
                    handleChange(e, props.dataItem.index, "quantity")
                  }
                  // onBlur={() => handleQuantityBlur(props.dataItem)}
                />
              </td>
            ) : (
              <td>{props.dataItem.quantity}</td>
            );
          }}
        />
        <GridColumn
          field="amount"
          title="Amount"
          width="120"
          cell={(props) => (
            <td>
              {editID === props.dataItem.index
                ? parseFloat(
                    (
                      parseFloat(editingRow.quantity) *
                      parseFloat(editingRow.price)
                    ).toFixed(2)
                  ) || "0"
                : parseFloat(
                    (
                      parseFloat(props.dataItem.quantity) *
                      parseFloat(props.dataItem.price)
                    ).toFixed(2)
                  ) || "0"}
            </td>
          )}
          editable={false}
        />
        <GridColumn
          field="description"
          title="Remarks"
          editor="text"
          width="200"
          cell={renderDescriptionCell}
        />
        <GridColumn
          field="operation"
          title="_"
          headerCell={AddRowCell}
          width="100"
          cell={(props) => (
            <td
              style={{
                padding: "0px",
                paddingLeft: "4px",
                paddingRight: "4px",
              }}
            >
              {isEditing(props.dataItem) ? (
                <>
                  <button
                    className="mr-4 text-green-700 disabled:opacity-25"
                    disabled={!editingRow.service || !editingRow.price>0 || !editingRow.quantity>0}
                    tabIndex={-1}
                    onClick={() => handleSave(null)}
                    title="Save"
                  >
                    <img src="save.svg" className="h-5" />
                  </button>
                  <button
                    className="text-red-500"
                    title="Cancel"
                    tabIndex={-1}
                    onClick={() => handleCancel(props.dataItem.index)}
                  >
                    <img src="closeIcon.svg" className="h-5 text-red-500" />
                  </button>
                </>
              ) : (
                <div>
                  <button
                    type="button"
                    className="mr-4 disabled:cursor-not-allowed"
                    disabled={isApproved}
                    tabIndex={-1}
                    onClick={() => handleEdit(props.dataItem)}
                  >
                    <img src="edit.svg" alt="" className={`w-6 cursor-pointer ${isApproved ? "grayscale opacity-30 cursor-default" : ""}`} />
                  </button>
                  <button
                    type="button"
                    disabled={isApproved}
                    className="disabled:cursor-not-allowed"
                    tabIndex={-1}
                    onClick={() => deleteData(props.dataItem.index)}
                  >
                    <img
                      src="trash.svg"
                      alt=""
                      className={`w-6 cursor-pointer ${isApproved ? "grayscale opacity-30 cursor-default" : ""}`}
                    />
                  </button>
                </div>
              )}
            </td>
          )}
        />
      </Grid>
    </div>
  );
};

export default EditableKendoTable;
