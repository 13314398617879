import React, { useContext, useEffect, useRef, useState } from "react";
import { formatDate } from "@telerik/kendo-intl";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  FlagIcon,
  LocationMarkerIcon,
  PlusCircleIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import EditableKendoTable from "./EditableKendoTable";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import axios from "axios";
import { BASE_URL, getUniqueId } from "../../constants";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import ReactModal from "react-modal";
import { toast, ToastContainer } from "react-toastify";
import { comment } from "postcss";
import AppContext from "../../context/app-context";
import { debounce } from "lodash";
import dayjs from "dayjs";
import LocationDetails from "./LocationDetails";

const calculateOptionTotal = (option) => {
  return option.services.reduce((optionTotal, location) => {
    const locationTotal = location.details.reduce((detailsTotal, detail) => {
      return detailsTotal + (parseFloat(detail.amount) || 0);
    }, 0);
    return optionTotal + locationTotal;
  }, 0);
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
  },
};

function ShipmentDetails({
  mode,
  shipment,
  setQuotation,
  quotation,
  logisticServices,
  originPorts,
  destinationPorts,
  defaultOption,
  defaultService,
  selectedItem,
  setSelectedItem,
  filterList,
}) {
  const statusStyles = {
    Draft: "text-yellow-500",
    Submitted: "text-blue-600",
    Approved: "text-green-600",
    Rejected: "text-red-500",
  };
  const rejectedReasons = [
    "Customer Request",
    "Incorrect Documentation",
    "Customs Clearance Issue",
    "Weather Conditions",
    "Vessel Delay",
    "Port Congestion",
    "Equipment Unavailability",
    "Damaged Cargo",
    "Regulatory Restrictions",
    "Payment Issues",
    "Other",
  ];
  const [selectedOption, setSelectedOption] = useState(shipment.options[0]);
  const [expandedLocations, setExpandedLocations] = useState(
    selectedOption.services.map((location) => location.id)
  );
  // const [editingKey, setEditingKey] = useState("");
  // const [form] = Form.useForm();
  // const [openDatePicker, setOpenDatePicker] = useState([]);
  // const [editQuoteDate, setQuoteDate] = useState(false);
  const [backupQuotation, setBackupQuotation] = useState(null);
  const [isCargoEditing, setIsCargoEditing] = useState(false);
  const [isReasonSaved, setIsReasonSaved] = useState(false);
  const [rejectionReason, setRejectionReason] = useState();
  // const [showStatusComponent, setShowStatusComponent] = useState(true);
  const [serviceTemplate, setServiceTemplate] = useState();
  const [requestingOptions, setRequestingOptions] = useState([]);
  // const [pendingSelection, setPendingSelection] = useState(null);

  const childRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // const saveTemplateServices = async (data, serviceId) => {
  //   try {
  //     const services = data.logisticsServices || [];
  //     setQuotation((prevQuotation) => {
  //       const updatedShipment = prevQuotation.shipment.map((shipment) => {
  //         if (shipment.mode !== mode) return shipment;

  //         return {
  //           ...shipment,
  //           options: shipment.options.map((option) => {
  //             if (option.id !== selectedOption.id) return option;

  //             return {
  //               ...option,
  //               services: option.services.map((location) => {
  //                 if (location.id !== serviceId) return location;

  //                 return {
  //                   ...location,
  //                   details: services.map((service) => ({
  //                     index: service.id.toString(),
  //                     serviceId: service.id,
  //                     service: service.name || "",
  //                     description: service.description || "",
  //                     price: service.price || 0,
  //                     quantity: service.quantity || 1,
  //                     amount: (service.price || 0) * (service.quantity || 1),
  //                   })),
  //                 };
  //               }),
  //             };
  //           }),
  //         };
  //       });

  //       return {
  //         ...prevQuotation,
  //         shipment: updatedShipment,
  //       };
  //     });
  //   } catch (errInfo) {
  //     console.error("Error saving template services:", errInfo);
  //   }
  // };

  // const handleOk = (serviceId) => {
  //   setIsModalOpen(false);
  //   setSelectedItem(pendingSelection);
  //   saveTemplateServices(pendingSelection, serviceId);
  //   setPendingSelection(null);
  // };

  const handleCancels = () => {
    setQuotation(backupQuotation);
    setIsCargoEditing(false);
  };
  // const handleCancel = () => {
  //   setIsModalOpen(false);
  //   setPendingSelection(null);
  // };

  // const handleServiceChange = (event) => {
  //   setPendingSelection(event.target.value);
  //   setIsModalOpen(true);
  // };

  const handleSaveReason = async () => {
    if (rejectionReason) {
      updateOptionData("rejectionReason", rejectionReason);
    } else {
      alert("Please select a rejection reason.");
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const editHandle = () => {
    setBackupQuotation(quotation);
    setIsCargoEditing(true);
  };

  const updateOptionData = async (field, value) => {
    let updatedOption = null;

    const updatedQuotation = {
      ...quotation,
      shipment: quotation.shipment.map((shipment) =>
        shipment.mode === mode
          ? {
            ...shipment,
            options: shipment.options.map((option) => {
              if (option.id === selectedOption.id) {
                const modifiedOption = {
                  ...option,
                  [field]: value,
                };
                updatedOption = modifiedOption; // Assign to updatedOption
                return modifiedOption;
              }
              return option;
            }),
          }
          : shipment
      ),
    };
    if (updatedOption) {
      if (field === "status" && value !== "Rejected") {
        await submitOptionStatus(updatedOption);
      } else if (field === "rejectionReason") {
        await submitOptionStatus(updatedOption);
        setIsReasonSaved(true);
      }
    }

    setQuotation(updatedQuotation);
  };

  const handleStatusChange = async (newStatus) => {
    updateOptionData("status", newStatus);
  };

  const cargoEditHandleChange = (e, field) => {
    const newValue = e.target.value;

    setQuotation((prevQuotation) => {
      const updatedShipment = prevQuotation.shipment.map((shipmentItem) => {
        // Check if shipment item mode matches the target mode
        if (shipmentItem.mode === shipment.mode) {
          const updatedOptions = shipmentItem.options.map((option) => {
            // Update only the selected option's cargoDetails
            if (option.id === selectedOption.id) {
              return {
                ...option,
                cargoDetailsOptions: {
                  ...option.cargoDetailsOptions,
                  [field]: newValue, // update the specific field in cargoDetails
                },
              };
            }
            return option;
          });
          // Return the shipment item with updated options
          return { ...shipmentItem, options: updatedOptions };
        }
        return shipmentItem;
      });

      // Return the updated quotation object with the modified shipment array
      return { ...prevQuotation, shipment: updatedShipment };
    });
  };

  const comboBoxHandleChange = (e, field) => {
    setQuotation((pre) => ({
      ...pre,
      shipment: pre.shipment.map((x) =>
        x.mode === mode
          ? {
            ...x,
            options: x.options.map((y) =>
              y.id === selectedOption.id
                ? {
                  ...y,
                  [field]: e.target.value.code,
                  [e.target.name]: e.target.value,
                }
                : y
            ),
          }
          : x
      ),
    }));
    // setSelectedOption((prevState) => ({
    //   ...prevState,
    //   [field]: e.target.value.code,
    //   [e.target.name]: e.target.value,
    // }));
  };

  const handleSave = () => {
    setIsCargoEditing(false);
  };

  const removeLocation = (locationId) => {
    setQuotation((prevQuotation) => ({
      ...prevQuotation,
      shipment: prevQuotation.shipment.map((shipment) =>
        shipment.mode === mode
          ? {
            ...shipment,
            options: shipment.options.map((option) =>
              option.id === selectedOption.id
                ? {
                  ...option,
                  services: option.services.filter(
                    (x) => x.id !== locationId
                  ),
                }
                : option
            ),
          }
          : shipment
      ),
    }));
  };

  // const handleComment = (comment, location) => {
  //   setQuotation((prevQuotation) => ({
  //     ...prevQuotation,
  //     shipment: prevQuotation.shipment.map((shipment) =>
  //       shipment.mode === mode
  //         ? {
  //             ...shipment,
  //             options: shipment.options.map((option) =>
  //               option.id === selectedOption.id
  //                 ? {
  //                     ...option,
  //                     services: option.services.map((loc) =>
  //                       loc.id === location.id
  //                         ? { ...loc, comment: comment }
  //                         : loc
  //                     ),
  //                   }
  //                 : option
  //             ),
  //           }
  //         : shipment
  //     ),
  //   }));
  // };

  const loadServices = async () => {
    const res = await axios.get(
      `${BASE_URL}/ServiceTemplate?searchText=~ALL&skip=0&take=100`
    );
    if (res.status === 200) {
      setServiceTemplate(res.data.data);
    }
  };
  useEffect(() => {
    loadServices();
  }, []);

  const removeOption = (id) => {
    setQuotation((prevQuotation) => {
      const updatedShipment = prevQuotation.shipment.map((s) => {
        if (s.mode === mode) {
          const updatedOptions = s.options.filter((option) => option.id !== id);

          // Determine the new selected option
          const selectedOption =
            updatedOptions.length > 0
              ? updatedOptions[updatedOptions.length - 1]
              : null;
          setSelectedOption(selectedOption);
          return {
            ...s,
            options: updatedOptions,
          };
        }
        return s;
      });

      return {
        ...prevQuotation,
        shipment: updatedShipment,
      };
    });
  };
  const addNewOption = () => {
    const origin = {
      id: quotation.originPortId,
      name: quotation.originPortName,
    };
    const destination = {
      id: quotation.destinationPortId,
      name: quotation.destinationPortName,
    };
    const locationId = Date.now().toString();
    setExpandedLocations((pre) => [...pre, locationId]);
    const newOption = {
      ...defaultOption,
      destinationPort: destination,
      destinationPortId: destination.id,
      originPort: origin,
      originPortId: origin.id,
      status: "Draft",
      isNew: true,
      id: Date.now().toString(),
      services: [
        {
          ...defaultService,
          id: locationId,
          destinationPort: destination,
          destinationPortId: destination.id,
          originPort: origin,
          originPortId: origin.id,
          details: [
            // {
            //   ...defaultService.details[0],
            //   id: Date.now(),
            // },
          ],
        },
      ],
    };
    setQuotation((prevQuotation) => {
      const updatedShipment = prevQuotation.shipment.map((s) => {
        const lastOption = s.options[s.options.length - 1];
        if (s.mode === mode) {
          return {
            ...s,
            options: [
              ...s.options,
              {
                ...newOption,
                cargoDetailsOptions: lastOption.cargoDetailsOptions,
              },
            ],
          };
        }
        return s;
      });

      return {
        ...prevQuotation,
        shipment: updatedShipment,
      };
    });

    setSelectedOption(newOption);
  };

  const addNewLocation = () => {
    const newLocId = getUniqueId();
    const newLocation = {
      ...defaultService,
      id: newLocId,
      details: [],
    };

    setQuotation((pre) => ({
      ...pre,
      shipment: pre.shipment.map((x) =>
        x.mode === mode
          ? {
            ...x,
            options: x.options.map((y) =>
              y.id === selectedOption.id
                ? {
                  ...y,
                  services: [...y.services, newLocation],
                }
                : y
            ),
          }
          : x
      ),
    }));
    setExpandedLocations([newLocId]);
  };

  const expandToggle = (id) => {
    setExpandedLocations(
      (prev) =>
        prev.includes(id)
          ? prev.filter((locId) => locId !== id) // Collapse
          : [...prev, id] // Expand
    );
  };

  // const isEditing = (record) => record.key === editingKey;

  // const edit = (record) => {
  //   form.setFieldsValue({ ...record });
  //   setEditingKey(record.key);
  // };

  // const cancel = () => {
  //   setEditingKey("");
  // };

  // const save = async (updatedData, locationId) => {
  //   try {
  //     setQuotation((prevQuotation) => {
  //       const updatedShipment = prevQuotation.shipment.map((s) => {
  //         if (s.mode === mode) {
  //           const updatedOptions = s.options.map((option) => {
  //             if (option.id === selectedOption.id) {
  //               return {
  //                 ...option,
  //                 services: selectedOption.services.map((location) => {
  //                   if (location.id === locationId) {
  //                     return {
  //                       ...location,
  //                       details: updatedData,
  //                     };
  //                   }
  //                   return location;
  //                 }),
  //               };
  //             }
  //             return option;
  //           });
  //           return {
  //             ...s,
  //             options: updatedOptions,
  //           };
  //         }
  //         return s;
  //       });
  //       return {
  //         ...prevQuotation,
  //         shipment: updatedShipment,
  //       };
  //     });
  //   } catch (errInfo) {
  //     console.error("Validate Failed:", errInfo);
  //   }
  // };

  // const getOptionTotal = (option) => {
  //   const total = calculateOptionTotal(option);
  //   return total.toFixed(2); // Format to 2 decimal places
  // };

  // const onServiceExpectedChange = (location, date) => {
  //   const selectedDate = new Date(date); // Parse the date string into a JavaScript Date
  //   selectedDate.setHours(12, 0, 0); // Adjust the time to noon if needed
  //   // Adjust the time to noon if ne
  //   setQuotation((pre) => ({
  //     ...pre,
  //     shipment: pre.shipment.map((x) =>
  //       x.mode === shipment.mode
  //         ? {
  //             ...x,
  //             options: x.options.map((y) =>
  //               y.id === selectedOption.id
  //                 ? {
  //                     ...y,
  //                     services: y.services.map((z) =>
  //                       z.id === location.id
  //                         ? {
  //                             ...z,
  //                             expectedDate: selectedDate,
  //                           }
  //                         : z
  //                     ),
  //                   }
  //                 : y
  //             ),
  //           }
  //         : x
  //     ),
  //   }));
  //   setOpenDatePicker((pre) => pre.filter((x) => x !== location.id));
  // };

  const getOptionData = () => {
    return (
      quotation?.shipment
        ?.find((x) => x.mode === shipment.mode)
        ?.options?.find((x) => x.id === selectedOption.id) || null
    );
  };

  const submitOptionStatus = async (option) => {
    setRequestingOptions([option.id]);
    const res = await axios.post(
      `${BASE_URL}/Quotation/UpdateOptionStatus`,
      option
    );
    if (res.status === 200) {
      toast.success("Option Status Saved");
    } else {
      toast.error("An error occured while saving Status!");
    }
    setRequestingOptions([]);
  };

  return (
    <div>
      <div className="flex gap-2 w-full mb-3 border-b-2 border-gray-300">
        {shipment.options.map((item, index) => (
          <div
            key={index}
            onClick={() => handleOptionSelect(item)}
            className={`text-blue-500   border-blue-500 cursor-pointer  w-fit  ${selectedOption.id === item.id && "bord"
              }`}
          >
            <div className="flex items-center gap-2">
              {quotation.modes.find((x) => x.id === mode)?.mode} option{" "}
              {index + 1}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  removeOption(item.id);
                }}
                className="ml-2 text-xl text-red-500 hover:text-red-700 hover:scale-105 "
              >
                &times;
              </button>
              <div>
                <span className="border-r-2 border-gray-300 ml-2" />
              </div>
            </div>

            {selectedOption.id === item.id && (
              <span className="border-b-2 border-blue-500 flex mt-2 -mb-[1px]  w-full " />
            )}
          </div>
        ))}
        <div
          onClick={addNewOption}
          className="cursor-pointer flex gap-2 items-start text-blue-500"
        >
          <PlusIcon className="w-4 h-4 outline-1 outline mt-1" />
          New {quotation.modes.find((x) => x.id === mode)?.mode} Option
        </div>
      </div>

      <div className="my-4 mx-0 flex pr-4 w-full justify-between rounded-lg  border bg-gray-100 shadow-sm hover:bg-gray-200 transition-colors duration-200">
        <div
          className="grid gap-2 gap-y-3 w-2/3 text-sm px-4 py-3 relative"
          style={{ gridTemplateColumns: "repeat(3, 1fr)" }}
        >
          {/* Origin */}

          {/* Charge Weight */}
          <div className="flex flex-col gap-1">
            <div className="text-gray-400 text-xs">Charge Weight</div>
            {isCargoEditing ? (
              <div className="w-full" id="poCompo">
                <NumericTextBox
                  className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                  value={getOptionData()?.cargoDetailsOptions?.chargeWeight}
                  onChange={(e) => cargoEditHandleChange(e, "chargeWeight")}
                />
              </div>
            ) : (
              <div className="text-xs">
                {`${getOptionData()?.cargoDetailsOptions?.chargeWeight || 0
                  } Kg`}
              </div>
            )}
          </div>

          {/* Quantity */}
          <div className="flex flex-col gap-1">
            <div className="text-gray-400 text-xs">Quantity</div>
            {isCargoEditing ? (
              <div className="w-full" id="poCompo">
                <NumericTextBox
                  className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                  value={getOptionData()?.cargoDetailsOptions?.quantity}
                  onChange={(e) => cargoEditHandleChange(e, "quantity")}
                />
              </div>
            ) : (
              <div className="text-xs">{`${getOptionData()?.cargoDetailsOptions?.quantity || 0
                }`}</div>
            )}
          </div>

          {/* Gross Volume */}
          {mode === 200 ? (
            <div className="flex flex-col gap-1">
              <div className="text-gray-400 text-xs">Gross Volume</div>
              {isCargoEditing ? (
                <div className="w-full" id="poCompo">
                  <NumericTextBox
                    className="w-full h-8 rounded-md border-zinc-300 text-md text-neutral-700"
                    value={getOptionData()?.cargoDetailsOptions?.grossVolume}
                    onChange={(e) => cargoEditHandleChange(e, "grossVolume")}
                  />
                </div>
              ) : (
                <div className="text-xs">{`${getOptionData()?.cargoDetailsOptions?.grossVolume || 0
                  } m3`}</div>
              )}
            </div>
          ) : (
            <div className="flex flex-col gap-1">
              <div className="text-gray-400 text-xs">Gross Weight</div>
              {isCargoEditing ? (
                <div className="w-full" id="poCompo">
                  <NumericTextBox
                    className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                    value={getOptionData()?.cargoDetailsOptions?.grossWeight}
                    onChange={(e) => cargoEditHandleChange(e, "grossWeight")}
                  />
                </div>
              ) : (
                <div className="text-xs">{`${getOptionData()?.cargoDetailsOptions?.grossWeight || 0
                  } Kg`}</div>
              )}
            </div>
          )}

          <div className="flex flex-col gap-1">
            <div className="text-gray-400 text-xs">Origin</div>
            {isCargoEditing ? (
              <div className="w-full" id="poCompo">
                <ComboBox
                  className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                  data={originPorts}
                  placeholder="Origin"
                  name="originPort"
                  id="id"
                  textField="name"
                  value={getOptionData()?.originPort}
                  onChange={(e) => comboBoxHandleChange(e, "originPortId")}
                />
              </div>
            ) : (
              <div className="text-xs">
                {getOptionData()?.originPort?.name || "_ _"}
              </div>
            )}
          </div>

          {/* Destination */}
          <div className="flex flex-col gap-1">
            <div className="text-gray-400 text-xs">Destination</div>
            {isCargoEditing ? (
              <div className="w-full" id="poCompo">
                <ComboBox
                  className="w-full h-8 rounded-md border-zinc-300 text-xs text-neutral-700"
                  data={destinationPorts}
                  placeholder="Destination"
                  name="destinationPort"
                  id="id"
                  textField="name"
                  value={getOptionData().destinationPort}
                  onChange={(e) => comboBoxHandleChange(e, "destinationPortId")}
                />
              </div>
            ) : (
              <div className="text-xs">
                {getOptionData()?.destinationPort?.name || "_ _"}
              </div>
            )}
          </div>

          {/* Edit/Save and Submit/Approve/Reject Buttons */}
          {getOptionData()?.status !== "Approved" && (
            <div className="flex flex-col gap-1 justify-center">
              {isCargoEditing ? (
                <div className="flex mt-5 ml-2">
                  <button
                    onClick={handleSave}
                    className="text-white px-2 py-1 rounded-md mr-2 bg-green-500"
                  >
                    Save
                  </button>
                  <button
                    onClick={handleCancels}
                    className="text-white px-2 py-1 rounded-md mr-2 bg-red-500"
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <img
                  src="edit.svg"
                  alt="edit"
                  className="w-5 cursor-pointer"
                  onClick={editHandle}
                />
              )}
            </div>
          )}
        </div>

        {/* Status Display */}
        {!getOptionData()?.isNew && (
          <div className="w-1/3">
            <div className="w-full flex flex-col items-end mt-4 space-y-2">
              <div className="flex items-center space-x-2 text-gray-700 text-xl font-medium">
                <span>Status:</span>
                <span className={`${statusStyles[getOptionData()?.status]}`}>
                  {getOptionData()?.status}
                </span>
              </div>

              {getOptionData()?.status === "Draft" && (
                <button
                  className="bg-blue-600 px-4 py-2 rounded-md font-semibold text-xs flex items-center gap-1 text-white"
                  onClick={() => handleStatusChange("Submitted")}
                  disabled={requestingOptions.includes(getOptionData()?.id)}
                >
                  {requestingOptions.includes(getOptionData()?.id) && (
                    <svg
                      class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  Submit
                </button>
              )}

              {getOptionData()?.status === "Submitted" && (
                <div className="flex space-x-4 mt-2">
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded-md text-xs font-semibold flex items-center gap-1"
                    onClick={() => handleStatusChange("Approved")}
                    disabled={requestingOptions.includes(getOptionData()?.id)}
                  >
                    {requestingOptions.includes(getOptionData()?.id) && (
                      <svg
                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Approve
                  </button>
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded-md text-xs font-semibold flex items-center gap-1"
                    onClick={() => handleStatusChange("Rejected")}
                    disabled={requestingOptions.includes(getOptionData()?.id)}
                  >
                    Reject
                  </button>
                </div>
              )}

              {getOptionData()?.status === "Rejected" && (
                <div>
                  {!getOptionData()?.rejectionReason ? (
                    <div className="flex gap-3 items-center mt-2" id="poCompo">
                      <ComboBox
                        className="w-full h-8 rounded-md bg-white border-zinc-300 text-xs text-neutral-700"
                        data={rejectedReasons}
                        placeholder="Rejected Reason"
                        name="rejectionReason"
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                      />
                      <button
                        onClick={handleSaveReason}
                        disabled={requestingOptions.includes(
                          getOptionData()?.id
                        )}
                        className="bg-blue-500 px-4 py-2 rounded-md text-xs font-semibold flex items-center gap-1"
                      >
                        {requestingOptions.includes(getOptionData()?.id) && (
                          <svg
                            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              class="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              class="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        )}
                        Save
                      </button>
                    </div>
                  ) : (
                    <span>{getOptionData()?.rejectionReason}</span>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="mb-6">
        {getOptionData()?.services.map((location, index) => (
          <LocationDetails
            isApproved={getOptionData()?.status === "Approved"}
            expandToggle={expandToggle}
            expandedLocations={expandedLocations}
            index={index}
            originPorts={originPorts}
            destinationPorts={destinationPorts}
            location={location}
            quotation={quotation}
            setQuotation={setQuotation}
            logisticServices={logisticServices}
            serviceTemplate={serviceTemplate}
            selectedOption={selectedOption}
            removeLocation={removeLocation}
            mode={mode}
          />
        ))}
        <div
          onClick={() => addNewLocation()}
          className="flex gap-5 mt-1 cursor-pointer items-center text-blue-500"
        >
          <PlusCircleIcon className="flex-shrink-0 h-5 w-5 " />
          <div className="">Add Location</div>
        </div>
        <div className="h-4 border-l-2 ml-2 border-dashed" />
        <div className="flex gap-2 mt-1 items-center text-blue-500">
          <FlagIcon className="flex-shrink-0 h-5 w-5  " />
          <div className="flex gap-3">
            <div className="text-black">{quotation.destination}</div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
}

export default ShipmentDetails;
